import { HEADER_LOADED } from './actions';
import { parseContent } from 'behavior/content';

export const initialState = {
  desktopContent: null,
  loaded: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case HEADER_LOADED:
      return {
        desktopContent: action.payload ? parseContent(action.payload.desktopContent) : null,
        loaded: true,
      };
    default:
      return state;
  }
};
