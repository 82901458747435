import { subAccountPageQuery, newSubAccountPageQuery, getLoadSubAccountsPreviewPageQuery } from './queries';
import { initSystemPageContent } from '../system';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';
import { arrayToObject } from 'utils/helpers';
import { RouteName } from 'routes';
import { PageComponentNames } from '../componentNames';
import { createNotFoundPageResult } from '../helpers';

export default ({ routeName, params }, _state$, { api }) => {
  const isEdit = routeName === RouteName.EditSubAccount;

  if (params?.previewToken) {
    const pageName = isEdit ? 'editSubAccount' : 'newSubAccount';

    return api.graphApi(getLoadSubAccountsPreviewPageQuery(pageName)).pipe(
      map(({ pages, profile: { subAccounts } }) => {
        const page = pages[pageName];
        if (!page)
          return null;

        page.component = PageComponentNames.SubAccount;
        page.options = subAccounts.options;
        page.authorizers = arrayToObject(Array.from(Array(3)).map((_, index) => ({
          id: index.toString(),
          name: `authorizer${index + 1}@sana-commerce.com`,
        })), a => a.id);

        if (isEdit)
          page.subAccount = {
            id: '',
            name: '',
            email: '',
            active: true,
            canOrder: true,
            canSeePrices: true,
            canSeeStock: true,
            canAuthorizeOrders: false,
            limits: [],
            dependentAccounts: [],
          };

        return { page };
      }),
      initSystemPageContent(),
    );
  }

  const id = params?.id;

  if (isEdit && !id)
    return of(null);

  const request = isEdit
    ? api.graphApi(subAccountPageQuery, { id })
    : api.graphApi(newSubAccountPageQuery);

  return request.pipe(
    map(({ pages, profile: { subAccounts, accountManagers } }) => {
      const page = pages.subAccount;
      if (!page)
        return null;

      if (!subAccounts)
        return { page: createNotFoundPageResult(page) };

      page.component = PageComponentNames.SubAccount;

      page.subAccount = subAccounts.search && subAccounts.search.list[0];
      page.authorizers = subAccounts.authorizers
        ? accountManagers.list.concat(subAccounts.authorizers.list)
        : accountManagers.list;
      page.authorizers = arrayToObject(page.authorizers, a => a.id);
      if (page.subAccount)
        delete page.authorizers[page.subAccount.id];

      page.options = subAccounts.options;

      if (isEdit && !page.subAccount)
        return null;

      return { page };
    }),
    initSystemPageContent(),
  );
};
