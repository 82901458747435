import PropTypes from 'prop-types';
import SummaryBase from './SummaryBase';

const SummaryBlock = ({ isDesignerMode }) => <SummaryBase isDesignerMode={isDesignerMode} />;

SummaryBlock.propTypes = {
  isDesignerMode: PropTypes.bool,
};

export default SummaryBlock;
