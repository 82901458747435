import { ofType } from 'redux-observable';
import { merge, of } from 'rxjs';
import {
  switchMap,
  mergeMap,
  map,
  startWith,
  pluck,
} from 'rxjs/operators';
import { setLoadingIndicator, unsetLoadingIndicator } from 'behavior/loadingIndicator';
import { retryWithToast } from 'behavior/errorHandling';
import {
  AGREEMENTS_REQUESTED,
  notifyAgreementsReceived,
  AGREEMENTS_SEARCH_PRODUCT_IDS,
  productIdsReceived,
  AGREEMENT_LINES_AVAILABILITY_REQUESTED,
  receiveAgreementLinesAvailability,
} from './actions';
import {
  agreementsListQuery,
  agreementItemQuery,
  searchForProductIdsQuery,
  linesAvailabilityQuery,
} from './queries';
import { isProductOrderable } from './util';
import { requestAbility } from 'behavior/user/epic';
import { AbilityState, AbilityTo } from 'behavior/user/constants';

export default function salesAgreementsPageEpic(action$, state$, dependencies) {
  const { api, logger } = dependencies;
  const setLoading = setLoadingIndicator();
  const unsetLoading = unsetLoadingIndicator();

  const onAgreementsRequested$ = action$.pipe(
    ofType(AGREEMENTS_REQUESTED),
    switchMap(({ payload }) => {
      const isSingleItemLoading = !!payload.id;

      const query = isSingleItemLoading ? agreementItemQuery : agreementsListQuery;
      const params = isSingleItemLoading
        ? { id: payload.id }
        : {
          input: {
            activeOnly: payload.activeOnly,
            page: { index: payload.index, size: payload.size },
          },
        };

      return api.graphApi(query, params).pipe(
        mergeMap(({ salesAgreements }) => {
          const actions = [unsetLoading];

          if (salesAgreements.list)
            actions.push(notifyAgreementsReceived(salesAgreements.list, payload.index ? true : false));
          else
            actions.push(notifyAgreementsReceived(salesAgreements.item && [salesAgreements.item]));

          return actions;
        }),
        retryWithToast(action$, logger, _ => of(unsetLoading)),
        startWith(setLoading),
      );
    }),
  );

  const onSearch$ = action$.pipe(
    ofType(AGREEMENTS_SEARCH_PRODUCT_IDS),
    switchMap(({ payload }) => {
      if (!payload.keywords)
        return of(productIdsReceived(payload.keywords, null));

      return api.graphApi(searchForProductIdsQuery, payload).pipe(
        map(({ catalog }) => {
          if (!catalog.products || !catalog.products.products.length)
            return productIdsReceived(payload.keywords, null);

          return productIdsReceived(payload.keywords, catalog.products.products.map(p => p.id));
        }),
        retryWithToast(action$, logger),
      );
    }),
  );

  const onAgreementLinesAvailabilityRequested$ = action$.pipe(
    ofType(AGREEMENT_LINES_AVAILABILITY_REQUESTED),
    pluck('payload'),
    switchMap(({ lines, status, id: agreementId  }) => requestAbility(AbilityTo.OrderProducts, state$, dependencies).pipe(
      switchMap(canOrderAbility => {
        if (canOrderAbility !== AbilityState.Available)
          return of(receiveAgreementLinesAvailability([]), unsetLoading);

        return requestAbility(AbilityTo.ViewUnitOfMeasure, state$, dependencies).pipe(
          map(canViewUomsAbility => calculateProductsOrderability(lines, status, canViewUomsAbility === AbilityState.Available)),
          switchMap(({ assumedOrderableLineIds, productIdsToCheckOnServer }) => {
            if (productIdsToCheckOnServer.length === 0)
              return of(receiveAgreementLinesAvailability([]), unsetLoading);

            return api.graphApi(linesAvailabilityQuery, { agreementId, productIds: productIdsToCheckOnServer }).pipe(
              pluck('salesAgreements', 'linesAvailability'),
              map(receivedOrderability => matchReceivedLines(assumedOrderableLineIds, receivedOrderability)),
              mergeMap(lines => [receiveAgreementLinesAvailability(lines), unsetLoading]),
              retryWithToast(action$, logger, _ => of(unsetLoading)),
            );
          }),
        );
      }),
      startWith(setLoading),
    )),
  );

  return merge(
    onAgreementsRequested$,
    onSearch$,
    onAgreementLinesAvailabilityRequested$,
  );
}

function calculateProductsOrderability(agreementLines, agreementStatus, userCanViewUoms) {
  const assumedOrderableLineIds = [];
  const productIds = new Set();

  agreementLines.forEach(line => {
    const isOrderable = isProductOrderable(line, agreementStatus, userCanViewUoms);
    if (!isOrderable)
      return;

    assumedOrderableLineIds.push(line.id);
    line.product?.id && productIds.add(line.product.id);
  });

  return {
    assumedOrderableLineIds,
    productIdsToCheckOnServer: [...productIds],
  };
}

function matchReceivedLines(assumedOrderableLineIds, receivedOrderability) {
  const lineIds = [];
  assumedOrderableLineIds.forEach(lineId => {
    if (receivedOrderability.some(receivedLine => receivedLine.lineId === lineId))
      lineIds.push({ lineId });
  });
  return lineIds;
}