import { catchError } from 'rxjs/operators';
import { forkJoin, noop, EMPTY, Observable, Observer } from 'rxjs';

export function createRequestTracker() {
  const activeRequests = new Set<Observable<unknown>>();

  function forgetRequest(source: Observable<unknown>) {
    setTimeout(() => activeRequests.delete(source), 0);
  }

  return {
    trackObservable<T>(source: Observable<T>) {
      return new Observable<T>((observer: Observer<T>) => {
        activeRequests.add(source);

        const subscription = source.subscribe(observer);

        return () => {
          forgetRequest(source);
          subscription.unsubscribe();
        };
      });
    },
    hasInProgress() {
      return !!activeRequests.size;
    },
    getReadyPromise() {
      if (!activeRequests.size)
        return Promise.resolve();

      return new Promise<void>((resolve, reject) => {
        forkJoin([...activeRequests].map(catchError(_ => EMPTY)))
          .subscribe(noop, reject, () => setTimeout(resolve, 0));
      });
    },
  };
}