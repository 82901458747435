import { loadBasketPageQuery } from './queries';
import { pluckAsPage } from 'behavior/pages/helpers';
import { map, mergeMap, filter } from 'rxjs/operators';
import { requestBasketPage, basketReceived } from 'behavior/basket';
import { of } from 'rxjs';
import { basketDetailsQuery, pageSize } from 'behavior/basket/queries';
import { PageComponentNames } from '../componentNames';
import { getCorrectPageIndex, redirectToPage } from 'behavior/basket/helpers';
import { initSystemPageContent } from '../system';
import { Presets } from './constants';

export default ({ params, options }, state$, { api }) => {
  const pageIndex = getPageIndex(params);

  if (params?.previewToken) {
    return api.graphApi(loadBasketPageQuery).pipe(
      pluckAsPage('basket', PageComponentNames.Basket),
      filter(Boolean),
      initSystemPageContent(),
      map(({ page }) => ({
        page,
        action$: of(basketReceived({
          totalCount: 3,
          productLines: {
            totalCount: 3,
            list: Array.from(Array(3)).map((_, index) => ({
              quantity: 1,
              product: { id: (index + 1).toString() },
            })),
          },
          serviceLines: [],
          totals: { sub: 0, roundOff: 0, priceExcludingTax: 0, price: 0 },
          shippingCost: { price: 0 },
          paymentCost: {},
          discount: { invoice: 0, payment: 0 },
          tax: {},
        }, pageIndex)),
      })),
    );
  }

  if (options) {
    const page = state$.value.page;
    if (options.action)
      return of({ action$: of(options.action), page });

    if (options.linesOnly) {
      return of({
        action$: of(requestBasketPage(pageIndex, isB2CPreset(page.preset))),
        page,
      });
    }
  }

  return api.graphApi(loadBasketPageQuery).pipe(
    pluckAsPage('basket', PageComponentNames.Basket),
    filter(Boolean),
    initSystemPageContent(),
    map(({ page }) => ({
      page,
      params: {
        ...requestBasketPage(pageIndex, isB2CPreset(page.preset)).payload,
        loadCategories: state$.value.analytics && state$.value.analytics.isTrackingEnabled,
      },
    })),
    mergeMap(({ page, params }) => api.graphApi(basketDetailsQuery, params, { retries: 0 }).pipe(
      map(data => {
        const { basket } = data;
        const { totalCount } = basket.productLines;

        const correctedPageIndex = getCorrectPageIndex(pageIndex, pageSize, totalCount);
        const action$ = correctedPageIndex !== pageIndex
          ? of(redirectToPage(state$.value.routing.location.pathname, correctedPageIndex))
          : of(basketReceived(basket, pageIndex));

        return { page, action$ };
      }),
    )),
  );
};

function getPageIndex(params) {
  let pageIndex = 0;
  if (params && 'page' in params)
    pageIndex = +params.page - 1; // numbering in URL starts from 1

  return pageIndex;
}

function isB2CPreset(preset) {
  return preset === Presets.B2C;
}
