import { representationPage } from './queries';
import { map } from 'rxjs/operators';
import { PageComponentNames } from '../componentNames';
import { initSystemPageContent } from '../system';
import { loadSystemPageQuery } from '../system/queries';

export default ({ params }, _state$, { api }) => {
  if (params?.previewToken) {
    return api.graphApi(loadSystemPageQuery('represent')).pipe(
      map(({ pages: { represent: page } }) => {
        if (!page)
          return null;
  
        page.component = PageComponentNames.Represent;
        page.customers = {
          keywords: '',
          total: 5,
          list: Array.from(Array(5)).map((_, index) => ({
            id: index.toString(),
            name: '',
            address: { postalAddress: '' },
          })),
        };
  
        return { page };
      }),
      initSystemPageContent(),
    );
  }

  return api.graphApi(representationPage).pipe(
    map(({ pages, profile: { impersonation } }) => {
      const page = pages.represent;

      if (!impersonation || !page)
        return null;

      page.component = PageComponentNames.Represent;
      page.customers = impersonation.customers;
      page.customers.keywords = '';

      return { page };
    }),
    initSystemPageContent(),
  );
};
