import LanguageToggleBase from './LanguageToggleBase';
import { TogglePresentationOption } from './constants';

const defaultOptions = {
  togglePresentation: TogglePresentationOption.FlagOnly,
  showFlagsInDropdown: true,
};

const LanguageToggle = () => <LanguageToggleBase options={defaultOptions} />;

export default LanguageToggle;
