import type { Timing } from './types';

export const PROFILER_TIMING_RECEIVED = 'PROFILER_TIMING_RECEIVED' as const;
export const receiveTiming = (timing: Timing) => ({
  type: PROFILER_TIMING_RECEIVED,
  payload: timing,
});

export const PROFILER_REQUEST_CLOSE = 'PROFILER_REQUEST_CLOSE' as const;
export const closeProfiler = () => ({
  type: PROFILER_REQUEST_CLOSE,
});

export const PROFILER_CLOSED = 'PROFILER_CLOSED' as const;
export const profilerClosed = () => ({
  type: PROFILER_CLOSED,
});

export type ReceiveTimingAction = ReturnType<typeof receiveTiming>

export type ProfilerAction = ReturnType<
  | typeof closeProfiler
  | typeof profilerClosed
> | ReceiveTimingAction;
