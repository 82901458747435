import Field, { fieldPropTypes } from '../Field';
import { Input } from 'components/primitives/form';

const EmailAddressField = ({ validation, ...attributes }) => (
  <Field
    type="email"
    validation={{ ...validation, emailAddress: true }}
    as={Input}
    {...attributes}
  />
);

EmailAddressField.propTypes = fieldPropTypes;

export default EmailAddressField;
