import PropTypes from 'prop-types';
import AccountMenuBase from './AccountMenuBase';

const AccountMenuBlock = ({ model, isDesignerMode }) => (
  <AccountMenuBase options={model} isDesignerMode={isDesignerMode} />
);

AccountMenuBlock.propTypes = {
  model: PropTypes.shape({
    togglePresentation: PropTypes.number.isRequired,
  }).isRequired,
  isDesignerMode: PropTypes.bool,
};

export default AccountMenuBlock;
