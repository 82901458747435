import styles from '../../Order.module.scss';
import PropTypes from 'prop-types';
import { Link } from 'components/primitives/links';
import { routesBuilder } from 'routes';

const IdLink = ({ agreementId }) => {
  const route = routesBuilder.forSalesAgreement(agreementId);
  return <Link className={styles.agreementIdLink} to={route}>{agreementId}</Link>;
};

IdLink.propTypes = {
  agreementId: PropTypes.string.isRequired,
};

export default IdLink;