import { memo } from 'react';
import PropTypes from 'prop-types';
import { accordionId } from './MobileTopMenu';
import { OpenerButton } from 'components/objects/navigation';
import { useSanaTexts } from 'components/sanaText';
import { stripHtmlTagsAndFormatting } from 'utils/helpers';

const textsToLoad = ['Aria_MobileTopMenu_ToggleButton'];

const MobileTopMenuOpener = ({ expanded, setExpanded }) => {
  const [ariaTopMenuToggleButtonText] = useSanaTexts(textsToLoad, stripHtmlTagsAndFormatting).texts;

  return (
    <OpenerButton
      onClick={() => setExpanded(!expanded)}
      isActive={expanded}
      aria-label={ariaTopMenuToggleButtonText}
      aria-pressed={expanded}
      aria-controls={accordionId}
    />
  );
};

MobileTopMenuOpener.propTypes = {
  expanded: PropTypes.bool,
  setExpanded: PropTypes.func.isRequired,
};

export default memo(MobileTopMenuOpener);
