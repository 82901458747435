import { map, first, switchMap, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { loadPageQuery } from './queries';
import { initComponent } from 'behavior/pages/helpers';
import { PageComponentNames } from '../componentNames';
import { requestAbility } from 'behavior/user/epic';
import { AbilityState, AbilityTo } from 'behavior/user/constants';
import { initSystemPageContent, loadSystemPageQuery } from 'behavior/pages/system';

export default ({ params }, state$, { api }) => {
  if (params?.previewToken) {
    return api.graphApi(loadSystemPageQuery('wishList')).pipe(
      map(({ pages: { wishList: page } }) => {
        if (!page)
          return null;
        
        page.component = PageComponentNames.WishList;
        page.productLines = Array.from(Array(3)).map((_, index) => ({
          id: index.toString(),
          isOrderable: true,
          product: { id: (index + 1).toString() },
        }));

        return { page };
      }),
      initSystemPageContent(),
    );
  }

  return state$.pipe(
    first(state => state.settings.loaded),
    switchMap(({ settings }) => settings.wishListEnabled
      ? loadPage(api, state$)
      : of(null),
    ),
  );
};

function loadPage(api, state$) {
  return requestAbility(AbilityTo.ViewPrices, state$, { api }).pipe(
    mergeMap(abilityState => api.graphApi(loadPageQuery, { calculated: abilityState !== AbilityState.NotAvailable }).pipe(
      map(result => mapResult(result)),
      initComponent(PageComponentNames.WishList),
      initSystemPageContent(),
    )),
  );
}

function mapResult(
  {
    pages: {
      wishList: page,
    },
    wishList,
  },
) {

  if (!page)
    return null;

  return {
    ...page,
    ...wishList,
  };
}