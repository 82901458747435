import Banner from './ErrorBanner';
import { PageComponentNames } from 'behavior/pages';

function errorRenderer(page) {
  return <Banner timeId={page.data?.timeId} />;
}

export default {
  [PageComponentNames.Error]: errorRenderer,
};
