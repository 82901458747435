import ErrorToastWithReload from './ErrorToastWithReload';
import ErrorToastWithRetry from './ErrorToastWithRetry';
import ErrorToastWithMessage from './ErrorToastWithMessage';
import { errorToasts } from 'behavior/errorHandling';

/* eslint-disable react/no-multi-comp */
const toastsCreators = {
  [errorToasts.Retry]: (id, data) => <ErrorToastWithRetry {...data} id={id} />,
  [errorToasts.Reload]: (_id, data) => <ErrorToastWithReload {...data}  />,
  [errorToasts.Message]: (_id, data) => <ErrorToastWithMessage message={data} />,
};

export default function create(id, type, data) {
  const creator = toastsCreators[type];
  if (!creator)
    throw new Error(`Error toast of type ${type} is not registered.`);

  return creator(id, data);
}
