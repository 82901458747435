/* eslint-disable react/no-multi-comp */
import styles from '../Checkout.module.scss';
import { useRef, useEffect } from 'react';
import { SimpleText, useSanaTexts } from 'components/sanaText';
import { LazyImage } from 'components/primitives/responsiveImages';
import { makeSimpleText } from 'utils/render';
import { connect } from 'react-redux';
import { getFormatNumber, getFormatPrice, getFormatPercentage, formatAsPercentage } from 'utils/format';
import { UomTitle } from 'components/primitives/product';
import { identity } from 'rxjs';
import { useHasAbilities } from 'components/objects/user';
import { AbilityTo } from 'behavior/user/constants';
import { requestLines } from 'behavior/pages/checkout';
import { fixIncorrectScrollAnchoring, iEquals } from 'utils/helpers';
import { toggleOverflowAnchorState } from 'components/primitives/transitions';
import { overviewLinesPropTypes } from '../propTypes';
import OverviewLinesFooter from './OverviewLinesFooter';

const MobileOverviewLines = ({
  productLines,
  serviceLines,
  linesLoaded,
  showFooter,
  collapseState,
  noImageUrl,
  culture,
  currency,
  showImages,
  dispatch,
}) => {
  const [collapsed, setCollapsed] = collapseState;

  const [noImageText, agreementLine] = useSanaTexts(['Product_NoImage', 'AgreementLine'], makeSimpleText).texts;
  const formatNumber = getFormatNumber(culture);
  const formatPercentage = getFormatPercentage(culture, 2);
  const showPrices = useHasAbilities(AbilityTo.ViewPrices)[0];
  const formatPrice = currency && showPrices ? getFormatPrice(currency) : identity;

  const slidingTimeoutRef = useRef();
  useEffect(() => () => clearTimeout(slidingTimeoutRef.current), []);

  const onShowMoreClick = () => {
    fixIncorrectScrollAnchoring();
    toggleOverflowAnchorState(true);
    slidingTimeoutRef.current = setTimeout(toggleOverflowAnchorState, 50);

    if (linesLoaded) {
      setCollapsed(!collapsed);
      return;
    }

    setCollapsed(false);
    dispatch(requestLines());
  };

  return (
    <div className={styles.mobileLines + (showImages ? '' : ' ' + styles.noImages)}>
      <ul>
        {!!productLines.list && !collapsed && productLines.list.map((line, index) => {
          let imageUrl;
          if (line.product && line.product.image)
            imageUrl = line.product.image.small;

          if (line.subLines && line.subLines.length) {
            return (
              <li className={styles.line} key={index}>
                <div className={styles.withoutThumbnail}>
                  {renderProductTitle(line.product.title, line.product.id)}
                </div>
                {line.subLines.map((subLine, index) => {
                  const subLineImage = line.product.images.find(i => iEquals(i.variantId, subLine.variationId));

                  const subLineImageUrl = subLineImage && subLineImage.small || imageUrl;
                  const agreementTerm = subLine.salesAgreementLineId && `${agreementLine}: ${subLine.salesAgreementLineId}`;

                  return (
                    <div className={styles.subline} key={index}>
                      <div className={styles.topLine}>
                        {showImages &&
                          <div aria-hidden className={styles.thumbnailCell}>
                            <LazyImage
                              src={subLineImageUrl || noImageUrl}
                              title={imageUrl ? title : noImageText}
                              alt={title}
                              wrapperClassName={styles.thumbnail}
                              aria-hidden
                            />
                          </div>
                        }
                        <div className={styles.productInfo}>
                          <div className={`${styles.product} ${styles.variantTitle}`}>
                            {subLine.title}
                            {agreementTerm && <span className={styles.agreementLineId}>{agreementTerm}</span>}
                          </div>
                          <div className={`${styles.nestedLine} ${subLine.discount > 0 ? styles.hasDiscount : ''}`}>
                            <div>
                              <span className={styles.quantity}>{formatNumber(subLine.quantity)}</span>
                              {subLine.uom && <div className={styles.uom}><UomTitle id={subLine.uom.id} description={subLine.uom.description} /></div>}
                            </div>
                            {showPrices && renderPriceNode(subLine, currency, formatPrice, formatAsPercentage)}
                          </div>
                          {renderExtendedTexts(subLine.extendedTexts)}
                          {renderServiceLines(subLine.serviceLines, showPrices, currency, formatNumber, formatPrice)}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </li>
            );
          }

          const title = line.title || line.product.title;
          const agreementTerm = line.salesAgreementLineId && `${agreementLine}: ${line.salesAgreementLineId}`;

          return (
            <li key={index} className={styles.line}>
              <div className={styles.topLine}>
                {showImages &&
                  <div aria-hidden className={styles.thumbnailCell}>
                    <LazyImage src={imageUrl || noImageUrl} title={imageUrl ? title : noImageText} alt={title} wrapperClassName={styles.thumbnail} aria-hidden />
                  </div>
                }
                <div className={styles.productInfo}>
                  {renderProductTitle(title, line.product.id, undefined, agreementTerm)}
                  <div className={`${styles.nestedLine} ${line.discount > 0 ? styles.hasDiscount : ''}`}>
                    <div>
                      <span className={styles.quantity}>{formatNumber(line.quantity)}</span>
                      {line.uom && <div className={styles.uom}><UomTitle id={line.uom.id} description={line.uom.description} /></div>}
                    </div>
                    {showPrices && renderPriceNode(line, currency, formatPrice, formatPercentage)}
                  </div>
                  {renderExtendedTexts(line.extendedTexts)}
                  {renderServiceLines(line.serviceLines, showPrices, currency, formatNumber, formatPrice)}
                </div>
              </div>
            </li>
          );
        })}
        {!!serviceLines.length && !collapsed
          && renderServiceLines(serviceLines, showPrices, currency, formatNumber, formatPrice, false)
        }
      </ul>
      {showFooter &&
        <OverviewLinesFooter
          totalCount={productLines.totalCount}
          collapsed={collapsed}
          onShowMoreClick={onShowMoreClick}
        />
      }
    </div>
  );
};

MobileOverviewLines.propTypes = overviewLinesPropTypes;

export default connect(({ settings: { product, currency, basket }, localization }) => ({
  noImageUrl: product ? product.noImage.small : null,
  culture: localization.currentLanguage.cultureName,
  showImages: basket.showImages,
  currency,
}))(MobileOverviewLines);

function renderProductTitle(title, id, className = '', agreementTerm = null) {
  return (
    <div className={`${styles.product} ${className}`}>
      <span className={styles.title}>{title}</span>
      <span className={styles.id}>{id}</span>
      {agreementTerm && <span className={styles.agreementLineId}>{agreementTerm}</span>}
    </div>
  );
}

function renderPriceNode(line, currency, formatPrice, formatPercentage) {
  return (
    <div className={styles.total}>
      <span className="visually-hidden">{currency && currency.symbol + line.subTotal}</span>
      <span aria-hidden>{formatPrice(line.subTotal)}</span>
      {line.discount > 0 &&
        <div className={styles.discountWrapper}>
          <span className={styles.discount}>{formatPercentage(line.discount, true)}</span>
          <SimpleText textKey="Discount" />
        </div>
      }
    </div>
  );
}

function renderServiceLines(serviceLines, showPrices, currency, formatNumber, formatPrice, isInnerLine = true) {
  if (!serviceLines)
    return null;

  return serviceLines.map((serviceLine, index) => (
    <li key={index} className={`${styles.serviceLine} ${!isInnerLine ? styles.line : ''}`}>
      {renderProductTitle(serviceLine.title, serviceLine.id, !isInnerLine ? styles.withoutThumbnail : '')}
      <div className={`${styles.nestedLine} ${!isInnerLine ? styles.withoutThumbnail : ''}`}>
        <span className={styles.quantity}> {formatNumber(serviceLine.quantity)}</span>
        {showPrices &&
          <div className={styles.total}>
            <span className="visually-hidden">{currency && currency.symbol + serviceLine.price}</span>
            <span aria-hidden>{formatPrice(serviceLine.price)}</span>
          </div>
        }
      </div>
    </li>
  ));
}

function renderExtendedTexts(extendedTexts) {
  if (!extendedTexts)
    return null;

  return extendedTexts.map((text, index) => (
    <div key={index} className={styles.nestedLine}>
      <span>{text.title}</span>
    </div>
  ));
}