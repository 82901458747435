import styles from './LimitedAccess.module.scss';
import { Helmet } from 'react-helmet';
import { Container } from 'components/primitives/grid';
import { useSanaTexts } from 'components/sanaText';
import { Logo } from 'components/objects/logo';
import { makeSimpleText } from 'utils/render';
import { useVerticalOverflowFix } from 'utils/hooks';

const textKeys = ['LimitedAccessMode_PageTitle', 'LimitedAccessMode_Title', 'LimitedAccessMode_Message'];

export default () => {
  const [pageTitle, title, message] = useSanaTexts(textKeys, makeSimpleText).texts;

  useVerticalOverflowFix();

  return (
    <div id="unavailablePage">
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="robots" content="noindex,nofollow" />
      </Helmet>
      <Container className={styles.container}>
        <Logo className={styles.logo} />
        <h1 className={styles.title}>{title}</h1>
        <div className={styles.message}>{message}</div>
      </Container>
    </div>
  );
};