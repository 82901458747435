import styles from './Represent.module.scss';
import PropTypes from 'prop-types';
import { UseSanaTexts } from 'components/sanaText';
import { makeSimpleText, renderHTML } from 'utils/render';
import btnStyles from 'components/primitives/buttons/Button.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { representCustomer, resetImpersonationFailed } from 'behavior/user';
import { SanaButton } from 'components/primitives/buttons';
import { useIsMobileViewport } from 'utils/hooks';
import { Table } from 'components/primitives/table';
import { Placeholder } from 'components/primitives/placeholders';
import { useIsPreview } from 'components/objects/preview';

const textKeys = [
  'RepresentCustomer_CustomerNo',
  'RepresentCustomer_CustomerName',
  'RepresentCustomer_CustomerAddress',
];

const RepresentationTable = ({ list }) => {
  const isMobile = useIsMobileViewport();
  const isPreview = useIsPreview();

  const dispatch = useDispatch();
  const currentCustomerId = useSelector(customerIdSelector);

  const represent = customerId => {
    dispatch(resetImpersonationFailed());
    dispatch(representCustomer(customerId));
  };

  return (
    <UseSanaTexts options={textKeys}>
      {([id, name, address]) => {
        id = makeSimpleText(id);
        name = makeSimpleText(name);
        address = makeSimpleText(address);
        return (
          <Table className={`${styles.table} ${isMobile ? 'mobile' : '' }`}>
            <thead>
              <tr>
                <th className="text-left" id="id-header">{id}</th>
                <th className="text-left" id="name-header">{name}</th>
                <th className="text-left" id="address-header">{address}</th>
                <th className={styles.selectColumnHeader} />
              </tr>
            </thead>
            <tbody>
              {list.map(customer => {
                const isCurrent = currentCustomerId === customer.id || null;
                return (
                  <tr key={customer.id} aria-selected={isCurrent} aria-label={customer.name}>
                    <td className={styles.id}>{isPreview ? <Placeholder /> : customer.id}</td>
                    <td className={styles.name}>{isPreview ? <Placeholder /> : customer.name}</td>
                    <td className={styles.address}>{isPreview ? <Placeholder /> : renderHTML(customer.address.postalAddress)}</td>
                    <td className={styles.select}>
                      <SanaButton textKey={isCurrent ? 'Selected' : 'Select'}
                        className={`${btnStyles.btnSmall} ${btnStyles.btnAction}`}
                        disabled={isCurrent}
                        onClick={isCurrent ? null : () => represent(customer.id)}
                        aria-current={isCurrent}
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        );
      }}
    </UseSanaTexts>
  );
};

RepresentationTable.propTypes = {
  list: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    address: PropTypes.shape({
      postalAddress: PropTypes.string.isRequired,
    }),
  })),
};

export default RepresentationTable;

function customerIdSelector(state) {
  if (!state.user.isImpersonating)
    return null;
  return state.user.customer && state.user.customer.id;
}