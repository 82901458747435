export const AGREEMENT_APPLY = 'AGREEMENT_APPLY';
export const applyAgreement = salesAgreementId => ({
  type: AGREEMENT_APPLY,
  payload: { salesAgreementId },
});

export const AGREEMENT_CANCEL = 'AGREEMENT_CANCEL';
export const cancelAgreement = () => ({
  type: AGREEMENT_CANCEL,
});

export const AGREEMENT_APPLIED = 'AGREEMENT_APPLIED';
export const agreementApplied = salesAgreementId => ({
  type: AGREEMENT_APPLIED,
  payload: { salesAgreementId },
});

export const AGREEMENT_CANCELED = 'AGREEMENT_CANCELED';
export const agreementCanceled = () => ({
  type: AGREEMENT_CANCELED,
});

export const AGREEMENT_TERMS_APPLY = 'AGREEMENT_TERMS_APPLY';
export const applyAgreementTerms = () => ({
  type: AGREEMENT_TERMS_APPLY,
});