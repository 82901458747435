import styles from '../../Order.module.scss';
import PropTypes from 'prop-types';
import { useSanaTexts } from 'components/sanaText';
import TermLink from './TermLink';

const SalesAgreementTerm = ({ salesAgreementLineId, agreementId }) => {
  const { texts: [agreementLine] } = useSanaTexts(['AgreementLine']);
  return (
    <span className={styles.agreementLineId}>
      {agreementLine}
      {<TermLink agreementId={agreementId} salesAgreementLineId={salesAgreementLineId} />}
    </span>
  );
};

SalesAgreementTerm.propTypes = {
  agreementId: PropTypes.string.isRequired,
  salesAgreementLineId: PropTypes.string.isRequired,
};

export default SalesAgreementTerm;
