import styles from './SalesAgreements.module.scss';
import { memo } from 'react';
import PropTypes from 'prop-types';
import { InfoAlert } from 'components/primitives/alerts';
import { makeRichText } from 'utils/render';
import { useSanaTexts } from 'components/sanaText';
import { ContactStoreLink } from 'components/primitives/links';
import { iEquals } from 'utils/helpers';

const ACTIVE_STATUS = 'Active';

const Messages = ({ agreement, userCurrency }) => {
  const [
    salesAgreementCannotBeUsed,
    salesAgreementNotActive,
  ] = useSanaTexts([
    'SalesAgreementWithSuchCurrencyCannotBeUsed',
    'SalesAgreementNotActive',
  ]).texts;

  if (agreement.currencyId && !iEquals(agreement.currencyId, userCurrency)) {
    const contactLink = <ContactStoreLink linkTextKey="AgreementTerm_Contact" />;

    return (
      <InfoAlert className={styles.salesAgreementWithSuchCurrencyCannotBeUsed}>
        {makeRichText(salesAgreementCannotBeUsed, [contactLink])}
      </InfoAlert>
    );
  }

  if (agreement.status !== ACTIVE_STATUS)
    return (
      <InfoAlert className={styles.notActive}>
        {makeRichText(salesAgreementNotActive)}
      </InfoAlert>
    );

  return null;
};

Messages.propTypes = {
  agreement: PropTypes.shape({
    currencyId: PropTypes.string,
    status: PropTypes.string,
  }).isRequired,
  userCurrency: PropTypes.string,
};

export default memo(Messages);
