import styles from './LanguageToggle.module.scss';
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, shallowEqual } from 'react-redux';
import { Link } from 'components/primitives/links';
import { getFlagImage } from './flags';

const Body = ({
  languages,
  selectedLanguage,
  alternateUrls,
  showFlags,
  className = '',
}) => {
  const { routeData, hasBackUrl } = useSelector(({ routing }) => ({
    routeData: routing.routeData, 
    hasBackUrl: !!routing.location.query?.backurl,
  }), shallowEqual);

  const [hash, setHash] = useState('');
  const linkClassName = `${styles.item} ${className}`;

  useEffect(() => {
    /* URL hash can only be added on client side during SSR because browser doesn't send hash to the server.
    But there is possible situation when URL contains "backurl" query parameter with unencoded hash because of redirect.
    It happens when unauthenticated user goes to the page with hash which requeries login, like "/checkout#overview".
    In case of redirect to login page server doesn't know anything about hash hence "backurl" is created without it.
    The same time browser adds original hash in case of 302 redirect to the new location.
    This allows us to preserve hash and redirect user back to proper checkout step.
    But we should exclude this hash for alternate languages to the current page
    because this hash is not a part of current page and belongs to "backurl".
    "backurl" parameter is expected to be added as result of server side redirect to login or represent pages.
    */
    const urlHash = window.location.hash;
    if (!hasBackUrl && urlHash)
      setHash(urlHash);
  });

  return languages.map(language => {
    const alternateUrl = alternateUrls.find(url => language.id === +url.key);

    if (alternateUrl == null)
      return null;

    const to = routeData.routeName
      ? {
        routeName: routeData.routeName,
        params: { ...routeData.params, language: language.id },
      }
      : undefined;
    const attributes = {};

    if (language !== selectedLanguage) {
      attributes.rel = 'alternate';
      attributes.hrefLang = language.twoLetterISOName;
    }
    const url = alternateUrl.path + hash;

    return (
      <Link key={language.id} url={url} to={to} className={linkClassName} {...attributes}>
        {showFlags && <img src={getFlagImage(language)} aria-label={language.displayName} alt={language.name} className={styles.flag} />}
        {language.displayName}
      </Link>
    );
  });
};

Body.propTypes = {
  languages: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      displayName: PropTypes.string.isRequired,
      twoLetterISOName: PropTypes.string.isRequired,
      imagePath: PropTypes.string,
    }).isRequired,
  ).isRequired,
  alternateUrls: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      path: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
  selectedLanguage: PropTypes.object.isRequired,
  showFlags: PropTypes.bool.isRequired,
  className: PropTypes.string,
};

export default Body;
