import htmlPackage from 'components/objects/htmlBlock/package';
import headerPackage from 'components/objects/headerBlock/package';
import bannerPackage from 'components/objects/banner/package';
import uspPackage from 'components/objects/usp/package';
import imageTilesPackage from 'components/objects/imageTiles/package';
import vdColumnTemplatePackage from 'components/visualDesigner/package';
import childMenuItemsPackage from 'components/objects/childMenuItems/package';
import productSetPackage from 'components/objects/productSet/package';
import imagePackage from 'components/objects/imageBlock/package';
import searchBarPackage from 'components/objects/searchBarBlock/package';
import spacerPackage from 'components/objects/spacerBlock/package';
import logoPackage from 'components/objects/logo/package';
import languageTogglePackage from 'components/objects/languageToggle/package';
import basketSummaryPackage from 'components/objects/basket/summary/package';
import navigationPackage from 'components/objects/navigation/package';
import accountMenuPackage from 'components/objects/accountMenu/package';
import shoppingCartSuggestionsPackage from 'components/objects/shoppingCartSuggestions/package';
import callUsMessage from 'components/objects/callUsMessage/package';

const defaultComponents = {
  ...htmlPackage,
  ...headerPackage,
  ...bannerPackage,
  ...uspPackage,
  ...imageTilesPackage,
  ...vdColumnTemplatePackage,
  ...childMenuItemsPackage,
  ...productSetPackage,
  ...imagePackage,
  ...searchBarPackage,
  ...spacerPackage,
  ...logoPackage,
  ...languageTogglePackage,
  ...basketSummaryPackage,
  ...navigationPackage,
  ...accountMenuPackage,
  ...shoppingCartSuggestionsPackage,
  ...callUsMessage,
};

export default defaultComponents;
