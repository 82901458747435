import PropTypes from 'prop-types';
import SummaryBase from './SummaryBase';

const Summary = ({ compact, hideLabel }) => <SummaryBase compact={compact} hideLabel={hideLabel} />;

Summary.propTypes = {
  compact: PropTypes.bool,
  hideLabel: PropTypes.bool,
};

export default Summary;
