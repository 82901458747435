import { loadSubAccountsPageQuery, getLoadSubAccountsPreviewPageQuery } from './queries';
import { initSystemPageContent } from '../system';
import { map } from 'rxjs/operators';
import { PageComponentNames } from '../componentNames';
import { generateKey } from 'utils/helpers';
import { createNotFoundPageResult } from '../helpers';

export default ({ params }, _state$, { api }) => {
  if (params?.previewToken) {
    return api.graphApi(getLoadSubAccountsPreviewPageQuery('subAccounts')).pipe(
      map(({ pages, profile: { subAccounts } }) => {
        const page = pages.subAccounts;
        if (!page)
          return null;
  
        page.component = PageComponentNames.SubAccounts;
        page.subAccounts = {
          list: Array.from(Array(5)).map(() => ({
            id: generateKey(),
            name: '',
            active: true,
            canAuthorizeOrders: false,
            canOrder: true,
            canSeePrices: true,
            canSeeStock: true,
          })),
        };
        page.options = subAccounts.options;
  
        return { page };
      }),
      initSystemPageContent(),
    );
  }

  return api.graphApi(loadSubAccountsPageQuery).pipe(
    map(({ pages, profile: { subAccounts } }) => {
      const page = pages.subAccounts;
      if (!page)
        return null;

     if (!subAccounts)
        return { page: createNotFoundPageResult(page) };

      page.component = PageComponentNames.SubAccounts;
      page.subAccounts = subAccounts.search;
      page.options = subAccounts.options;
      return { page };
    }),
    initSystemPageContent(),
  );
};