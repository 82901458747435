import { memo } from 'react'; 
import { useSelector } from 'react-redux';
import { renderRows } from 'components/containers';
import { desktop } from './defaultContent';

const DesktopHeaderTemplate = () => {
  const desktopContent = useSelector(state => state.header.desktopContent);

  return renderRows(desktopContent ?? desktop);
};

export default memo(DesktopHeaderTemplate);
