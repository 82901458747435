import { tap } from 'rxjs/operators';
import { parseContent } from 'behavior/content';

export const initSystemPageContent = () => {
  return tap(data => {
    if (!data)
      return;

    const { page } = data;
    if (page && page.content) {
      const { header, footer } = page.content;
      page.content.header = parseContent(header);
      page.content.footer = parseContent(footer);
    }
  });
};