import styles from './GalleryImage.module.scss';
import { memo, useRef, useEffect, useCallback, useState, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import Swipe from 'react-easy-swipe';
import Spinner from 'components/primitives/spinner/Spinner';
import ZoomMedia from './ZoomMedia';
import {
  scroll$,
  orientationChange$,
  useEventObservable,
} from 'utils/rxjs';
import { useSanaTexts } from 'components/sanaText';
import { makeSimpleText } from 'utils/render';

const GalleryImage = ({ index = 0, items = [], noImage, onChange, showLargeGallery }) => {
  const imageRef = useRef(null);
  const fadeTimer = useRef(0);
  const activeIndex = useRef(index);
  activeIndex.current = index;
  const [zoom, setZoom] = useState(false);
  const [productNoImageText] = useSanaTexts(['Product_NoImage'], makeSimpleText).texts;

  if (!items[activeIndex.current])
    activeIndex.current = 0;

  const imgProps = getItemProps(items[activeIndex.current], noImage, productNoImageText);
  const zoomSrc = getZoomSrc(items[activeIndex.current]);

  const onLoad = useCallback(() => {
    imageRef.current.addEventListener('mouseover', showZoom);
    imageRef.current.addEventListener('mousemove', showZoomOnMove);

    imageRef.current.parentElement.classList.add('fade-in');
  }, []);

  const goTo = useCallback(index => {
    if (index === activeIndex.current)
      return;
    const newItem = items[index];
    if (!newItem)
      return;

    const imgElement = imageRef.current;
    imgElement.parentElement.classList.remove('fade-in');

    activeIndex.current = index;

    clearTimeout(fadeTimer.current);

    fadeTimer.current = setTimeout(() => {
      const newProps = getItemProps(newItem, noImage);
      imgElement.src = newProps.src;
      imgElement.alt = newProps.title;

      if (imgProps.src === newProps.src) {
        imgElement.parentElement.classList.add('fade-in');
      }

      onChange && onChange(activeIndex.current);
    }, styles.fadeTime);
  }, [index]);

  const swipeNext = () => {
    if (!zoom)
      goTo(activeIndex.current + 1);
  };

  const swipePrev = () => {
    if (!zoom)
      goTo(activeIndex.current - 1);
  };

  const showZoom = useCallback(() => {
    const item = items[activeIndex.current];
    if (item && item.large) {
      setZoom(true);
    }
  }, []);

  const showZoomOnMove = () => {
    imageRef.current.removeEventListener('mousemove', showZoomOnMove);
    showZoom();
  };

  const hideZoom = useCallback(e => {
    setZoom(false);
  }, []);

  useEventObservable(scroll$, hideZoom);
  useEventObservable(orientationChange$, hideZoom);

  useLayoutEffect(() => {
    setZoom(false);
  }, [index]);

  useEffect(() => {
    goTo(index);

    return () => {
      imageRef.current.removeEventListener('mouseover', showZoom);
      imageRef.current.removeEventListener('mousemove', showZoomOnMove);
    };
  }, [index]);

  useEffect(() => {
    if (imageRef.current.complete)
      onLoad();
  }, []);

  const handleImageClick = () => {
    !zoom && showZoom();
    showLargeGallery && showLargeGallery();
  };
  const handleImageKeyPress = e => {
    // on Enter
    if (e.keyCode === 13) {
      handleImageClick();
    }
  };

  const imageNode = (
    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
    <img
      src={imgProps.src}
      title={imgProps.title}
      alt={imgProps.title}
      className={imgProps.className}
      onLoad={onLoad}
      onClick={handleImageClick}
      onKeyUp={handleImageKeyPress}
      ref={imageRef}
      onMouseDown={preventMiddleMouseButtonScroll}
      onMouseLeave={hideZoom}
      draggable={false}
    />
  );

  return (
    <Swipe className={styles.feature}
      allowMouseEvents
      onSwipeLeft={swipeNext}
      onSwipeRight={swipePrev}
    >
      <div className={styles.spinner}><Spinner /></div>
      {imageNode}
      {zoom && imageRef.current && <ZoomMedia src={zoomSrc} initiatorNode={imageRef.current} />}
    </Swipe>
  );
};

GalleryImage.propTypes = {
  items: PropTypes.array,
  index: PropTypes.number,
  noImage: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  showLargeGallery: PropTypes.func,
};

export default memo(GalleryImage);

function getItemProps(item, noImage, productNoImageText) {
  if (!item) {
    return {
      src: noImage,
      title: productNoImageText,
      className: styles.noImage,
    };
  }
  return {
    src: item.medium || item.small,
    title: item.title,
  };
}

function getZoomSrc(item) {
  return (item && item.large) || null;
}

function preventMiddleMouseButtonScroll(e) {
  if (e.button !== 1)
    return;

  e.preventDefault();
}