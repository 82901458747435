import { switchMap, map, pluck } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { headerLoaded } from './actions';
import { loadHeaderQuery } from './queries';
import { VIEWER_CHANGED, LANGUAGE_CHANGED } from 'behavior/events';
import { USER_PROFILE_UPDATED } from 'behavior/user';
import { ignoreIfVisualDesigner } from 'utils/rxjs';

export default (action$, state$, { api }) => action$.pipe(
  ofType(LANGUAGE_CHANGED, VIEWER_CHANGED, USER_PROFILE_UPDATED),
  ignoreIfVisualDesigner(state$),
  switchMap(() => api.graphApi(loadHeaderQuery).pipe(
    pluck('header'),
    map(headerLoaded),
  )),
);
