import styles from '../Checkout.module.scss';
import { memo, useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import dateOnly from 'date-only';
import { SanaForm, FormGroup } from 'components/objects/forms';
import { HiddenSubmitButton } from 'components/primitives/form';
import { TextBoxField, TextAreaField, DatePickerField } from 'components/objects/forms/fields';
import { useSanaTexts, UseSanaTexts } from 'components/sanaText';
import { makeSimpleText } from 'utils/render';
import { isVisible, FieldVisibility } from './constants';

const AdditionalInfoForm = ({
  formikRef,
  initialValues,
  commentVisibility,
  deliveryDateVisibility,
  referenceNoVisibility,
  onSubmit,
  onBlur,
  submitOnBlur,
}) => {
  const isReferenceVisible = isVisible(referenceNoVisibility),
    isCommentVisible = isVisible(commentVisibility),
    isDateVisible = isVisible(deliveryDateVisibility);

  const { texts: [referenceNoLbl, commentsLbl, dateLbl], loaded } = useSanaTexts([
    isReferenceVisible && 'ReferenceNumber',
    isCommentVisible && 'Comments',
    isDateVisible && 'RequestedDeliveryDate',
  ], makeSimpleText);
  const prevValuesRef = useRef();

  const newInitialValues = useMemo(() => {
    const result = { ...initialValues };

    if (!isReferenceVisible)
      delete result.referenceNo;
    if (!isCommentVisible)
      delete result.comment;
    if (!isDateVisible)
      delete result.deliveryDate;

    return result;
  }, [initialValues, isReferenceVisible, isCommentVisible, isDateVisible]);

  if (!loaded)
    return DatePickerField.textKeys ? <UseSanaTexts options={DatePickerField.textKeys} /> : null;

  const handleSubmit = values => {
    prevValuesRef.current = values;
    onSubmit(values);
  };

  const onFormBlur = submitOnBlur
    ? (e, formik) => {
      onBlur && onBlur();
      if (!formik.dirty || !formik.isValid || e.currentTarget.contains(e.relatedTarget || document.activeElement))
        return;

      if (prevValuesRef.current === formik.values)
        return;

      handleSubmit(formik.values);
    }
    : null;

  return (
    <SanaForm
      name="additional"
      formikRef={formikRef}
      onBlur={onFormBlur}
      onSubmit={handleSubmit}
      initialValues={newInitialValues}
      className={styles.additional}
    >
      {isReferenceVisible &&
        <FormGroup
          fieldName="referenceNo"
          fieldTitle={referenceNoLbl}
          fieldComponent={TextBoxField}
          maxLength={20}
          required={referenceNoVisibility === FieldVisibility.Required}
          validation={{ required: referenceNoVisibility === FieldVisibility.Required }}
        />
      }
      {isCommentVisible &&
        <FormGroup
          fieldName="comment"
          fieldTitle={commentsLbl}
          fieldComponent={TextAreaField}
          maxLength={2000}
        />
      }
      {isDateVisible &&
        <FormGroup
          fieldName="deliveryDate"
          fieldTitle={dateLbl}
          fieldComponent={DatePickerField}
          minDate={dateOnly.today()}
          required={deliveryDateVisibility === FieldVisibility.Required}
          validation={{ required: deliveryDateVisibility === FieldVisibility.Required }}
        />
      }
      <HiddenSubmitButton />
    </SanaForm>
  );
};

AdditionalInfoForm.propTypes = {
  formikRef: PropTypes.object,
  initialValues: PropTypes.object,
  commentVisibility: PropTypes.oneOf(Object.values(FieldVisibility)),
  deliveryDateVisibility: PropTypes.oneOf(Object.values(FieldVisibility)),
  referenceNoVisibility: PropTypes.oneOf(Object.values(FieldVisibility)),
  onSubmit: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  submitOnBlur: PropTypes.bool,
};

export default memo(AdditionalInfoForm);
