import PropTypes from 'prop-types';
import DesktopNavBase from './DesktopNavBase';

const DesktopNavBlock = ({ isDesignerMode }) => <DesktopNavBase isDesignerMode={isDesignerMode} />;

DesktopNavBlock.propTypes = {
  isDesignerMode: PropTypes.bool,
};

export default DesktopNavBlock;
