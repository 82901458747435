exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Logo_logo a{display:block}.Logo_logo img{display:inline-block;vertical-align:bottom;max-width:100%;-ms-interpolation-mode:bicubic}@media print,screen{html.print .Logo_logo{max-width:40%}}", ""]);

// exports
exports.locals = {
	"logo": "Logo_logo"
};