import styles from '../../Checkout.module.scss';
import { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { RichText, SimpleText } from 'components/sanaText';
import AddressForm from './AddressForm';
import LoadingIndicator from '../StepLoadingIndicator';
import { Checkbox } from 'components/primitives/form';
import { useOnChange } from 'utils/hooks';
import { DangerAlert } from 'components/primitives/alerts';

export const GuestAddressStepBody = ({
  profileFields,
  profileTemplateFields,
  shippingFields,
  shippingTemplateFields,
  onAddressFormSubmit,
  submitFormOnBlur,
  profileFormikRef,
  shippingFormikRef,
  showLoading = false,
  showValidation,
  onAddressFormValidate,
  validateFormOnBlur,
  onAddressOptionChange,
}) => {
  const allowShippingAddress = !!(shippingTemplateFields && shippingTemplateFields.length);

  const shippingFieldsAbsent = shippingFields == null;
  const [useSingleAddress, setUseSingleAddress] = useState(shippingFieldsAbsent);

  const prevShippingFormValuesRef = useRef();

  if (!allowShippingAddress || useSingleAddress) {
    if (shippingFormikRef.current)
      prevShippingFormValuesRef.current = shippingFormikRef.current.values;

    shippingFormikRef.current = null;
  }

  useOnChange(() => {
    prevShippingFormValuesRef.current = null;
  }, [shippingTemplateFields], false);

  const onGuestSingleAddressChange = () => {
    onAddressOptionChange && onAddressOptionChange(!useSingleAddress);
    setUseSingleAddress(!useSingleAddress);

    if (submitFormOnBlur && (!useSingleAddress || shippingFields))
      onAddressFormSubmit();
  };

  useEffect(() => setUseSingleAddress(shippingFieldsAbsent), [shippingFieldsAbsent]);

  return (
    <>
      {showValidation &&
        <DangerAlert role="alert" className={styles.validation}>
          <RichText textKey="GuestAddress_SelectionValidationMessage" />
        </DangerAlert>
      }
      <div className={styles.addressStepWrapper}>
        <div className={styles.description}>
          <RichText textKey="GuestAddress_Header" />
        </div>
        <h3 className={styles.addressTitle}><SimpleText textKey="BillingAddress" /></h3>
        <div className={styles.guestProfile}>
          <AddressForm name="address"
            templateFields={profileTemplateFields}
            values={profileFields}
            onSubmit={onAddressFormSubmit}
            submitOnBlur={submitFormOnBlur}
            formikRef={profileFormikRef}
            onFormValidate={onAddressFormValidate.bind(null, profileFormikRef)}
            validateOnBlur={validateFormOnBlur}
          />
        </div>
        {allowShippingAddress &&
          <div>
            <div className={styles.singleAddressCheckoutWrapper}>
              <Checkbox id="guestSingleAddress" checked={useSingleAddress} onChange={onGuestSingleAddressChange} />
              <label htmlFor="guestSingleAddress"><SimpleText textKey="GuestDeliverOrderToBillingAddress" /></label>
            </div>
            {!useSingleAddress &&
              <div className={styles.shippingAddress}>
                <h3 className={styles.addressTitle}><SimpleText textKey="ShippingAddress" /></h3>
                <AddressForm name="shipToAddress"
                  templateFields={shippingTemplateFields}
                  values={prevShippingFormValuesRef.current || shippingFields}
                  onSubmit={onAddressFormSubmit}
                  submitOnBlur={submitFormOnBlur}
                  formikRef={shippingFormikRef}
                  onFormValidate={onAddressFormValidate.bind(null, shippingFormikRef)}
                  validateOnBlur
                />
              </div>
            }
          </div>
        }
        {showLoading && <LoadingIndicator />}
      </div>
    </>
  );
};

GuestAddressStepBody.propTypes = {
  profileFields: PropTypes.object,
  profileTemplateFields: PropTypes.array,
  shippingFields: PropTypes.object,
  shippingTemplateFields: PropTypes.array,
  onAddressFormSubmit: PropTypes.func,
  submitFormOnBlur: PropTypes.bool,
  nextStepButton: PropTypes.node,
  profileFormikRef: PropTypes.object.isRequired,
  shippingFormikRef: PropTypes.object.isRequired,
  showLoading: PropTypes.bool,
  showValidation: PropTypes.bool,
  onAddressFormValidate: PropTypes.func,
  validateFormOnBlur: PropTypes.bool,
  onAddressOptionChange: PropTypes.func,
};
