import { insiteEditorUseRegisteredSanaTexts } from './insiteEditorUseRegisteredSanaTexts';
import { useSelector } from 'react-redux';
import { useRef, useMemo, useReducer } from 'react';

const useInsiteEditorTexts = (items, process, params) => {
  if (!useSelector(state => state.visualDesigner.insiteEditor))
    return items;

  const [, rerender] = useReducer(() => ({}), null);
  const optionsRef = useRef([]);
  const sanaTextsRef = useRef([]);

  const findText = (key, text) => {
    const option = optionsRef.current.find(option => option.key === key);

    if (sanaTextsRef.current.length > 0 && option) {
      const isTextUpdated = option.value === text;
      const optionIndex = optionsRef.current.findIndex(option => option.key === key);

      if (!isTextUpdated && isValidSanaTextValue(text)) {
        optionsRef.current = optionsRef.current.map((option, index) => {
          if (index === optionIndex)
            option.value = text;

          return option;
        });
        //need to force component rerender after deleting several texts to update the text values
        rerender();
      }

      return sanaTextsRef.current[optionIndex];
    }
    else {
      if (isValidSanaTextValue(text)) {
        optionsRef.current = [...optionsRef.current, { key, value: text }];
        rerender();
      }

      return text;
    }
  };

  if (params && params.textOnly)
    optionsRef.current.textOnly = true;

  if (params && params.isConstructedTexts)
    optionsRef.current.isConstructedTexts = true;

  const insiteEditorTexts = insiteEditorUseRegisteredSanaTexts(optionsRef.current);
  if (insiteEditorTexts)
    sanaTextsRef.current = insiteEditorTexts.texts;

  return useMemo(() => items?.map(item => process(item, findText)), [items, sanaTextsRef.current]);
};

export default useInsiteEditorTexts;

function isValidSanaTextValue(value) {
  return value !== null;
}