import PropTypes from 'prop-types';
import LanguageToggleBase from './LanguageToggleBase';

const LanguageToggleBlock = ({ model, isDesignerMode }) => (
  <LanguageToggleBase options={model} isDesignerMode={isDesignerMode} />
);

LanguageToggleBlock.propTypes = {
  model: PropTypes.shape({
    togglePresentation: PropTypes.number.isRequired,
    showFlagsInDropdown: PropTypes.bool.isRequired,
  }).isRequired,
  isDesignerMode: PropTypes.bool,
};

export default LanguageToggleBlock;
