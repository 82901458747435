import btnStyles from 'components/primitives/buttons/Button.module.scss';
import styles from './Page.module.scss';
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { SimpleText } from 'components/sanaText';
import { SanaLinkButton } from 'components/primitives/links';
import { Placeholder } from 'components/primitives/placeholders';
import ComparisonRow from './ComparisonRow';
import RatingRow from './rating';
import ActionSection from './ActionSection';
import SpecificationsGrid from './specifications';
import { requestProductComparisonInitialization } from 'behavior/productComparison/actions';
import { RailsSwiper } from './swiper';
import { useLoadEffect } from 'utils/hooks';
import { trackProductListView, EVENT_SOURCES } from 'behavior/analytics';

const Page = ({
  widgetState,
  data: { products, specifications, backTo },
  requestInitialization,
  trackProductListView,
}) => {
  useEffect(() => {
    if (!widgetState)
      requestInitialization();
  }, [widgetState]);

  useLoadEffect(() => {
    if (products && products.length > 0) {
      trackProductListView(products, EVENT_SOURCES.compareProductsPage);
    }
  }, [products]);

  const [showOnlyDifference, setShowOnlyDifference] = useState(false);
  const hasProducts = products && products.length;
  if (!hasProducts) {
    const placeholder = <Placeholder />;

    return (
      <div className="msg-block">
        <SimpleText textKey="ProductCompare_NoResult" />
        {backTo &&
          <div>
            <SanaLinkButton className={`${btnStyles.btnBig}`}
              to={backTo.routeData}
              url={backTo.url}
              textKey="ProductCompare_AddProductsToCompare"
              placeholder={placeholder}
            />
          </div>
        }
      </div>
    );
  }
  const showActionSection = products && products.length > 1;
  if (!showActionSection && showOnlyDifference) {
    setShowOnlyDifference(!showOnlyDifference);
  }

  return (
    <RailsSwiper className={styles.page}>
      <ComparisonRow productsToCompare={products} />
      {showActionSection && <ActionSection onShowOnlyDifferenceChanged={() => setShowOnlyDifference(!showOnlyDifference)} />}
      <RatingRow products={products} />
      <SpecificationsGrid specifications={specifications} showOnlyDifference={showOnlyDifference} />
    </RailsSwiper>
  );
};

Page.propTypes = {
  widgetState: PropTypes.object,
  data: PropTypes.shape({
    products: PropTypes.array,
    specifications: PropTypes.array,
    backTo: PropTypes.shape({
      url: PropTypes.string,
      routeData: PropTypes.shape({
        routeName: PropTypes.string.isRequired,
        params: PropTypes.object,
      }).isRequired,
    }),
  }).isRequired,
  requestInitialization: PropTypes.func.isRequired,
  trackProductListView: PropTypes.func.isRequired,
};

const mapStateToProps = ({ productComparison, page: { backTo } }) => ({
  widgetState: productComparison,
  backTo,
});

const mapDispatchToProps = {
  requestInitialization: requestProductComparisonInitialization,
  trackProductListView,
};

export default connect(mapStateToProps, mapDispatchToProps)(Page);