import {
  orderTemplatesQuery,
  orderTemplateLinesQuery,
  addToBasketMutation,
  removeTemplatesMutation,
  saveTemplateMutation,
} from './queries';
import {
  ORDER_TEMPLATES_REQUESTED,
  ORDER_TEMPLATE_LINES_REQUESTED,
  ORDER_TEMPLATES_ADDING_REQUESTED,
  ORDER_TEMPLATES_REMOVAL_REQUESTED,
  ORDER_TEMPLATE_SAVE,
  orderTemplatesReceived,
  orderTemplateLinesReceived,
  orderTemplatesAdded,
  orderTemplatesRemoved,
  orderTemplateSavingResultReceived,
} from './actions';
import { createApiCallEpic } from '../helpers';
import { combineEpics, ofType } from 'redux-observable';
import { LOCATION_CHANGED } from 'behavior/events';
import { of, from } from 'rxjs';
import { mergeMap, map, takeUntil, switchMap, startWith } from 'rxjs/operators';
import { concatToIfEmpty } from 'utils/rxjs';
import { retryWithToast } from 'behavior/errorHandling';
import { basketChangeStarted, basketChangeCompleted, navigateTo } from 'behavior/events';
import { unlockForm, FormLockKeys } from 'behavior/pages';
import { RouteName, routesBuilder } from 'routes';
import { trackAddToBasket } from 'behavior/analytics';
import { getProductsTrackingDataFromLines } from 'behavior/basket/util';

const loadOrderTemplatesEpic = createApiCallEpic(
  ORDER_TEMPLATES_REQUESTED,
  orderTemplatesQuery,
  orderTemplatesReceived,
);

const loadOrderTemplateLinesEpic = (action$, _state$, { api, logger }) => {
  const locationChanged$ = action$.pipe(ofType(LOCATION_CHANGED));

  return action$.pipe(
    ofType(ORDER_TEMPLATE_LINES_REQUESTED),
    mergeMap(action => api.graphApi(orderTemplateLinesQuery, action.payload).pipe(
      map(({ orderTemplates }) =>
        orderTemplateLinesReceived(action.payload.id,
          orderTemplates &&
          orderTemplates[0] &&
          orderTemplates[0].lines.filter(l => l.product.exists),
        )),
      retryWithToast(action$, logger),
      takeUntil(locationChanged$),
    )),
  );
};

const addToBasketEpic = (action$, state$, { api, logger }) => {
  const locationChanged$ = action$.pipe(ofType(LOCATION_CHANGED));
  return action$.pipe(
    ofType(ORDER_TEMPLATES_ADDING_REQUESTED),
    switchMap(action => api.graphApi(addToBasketMutation, { ...action.payload, requestModifiedLines: isTrackingEnabled(state$) }).pipe(
      mergeMap(({ orderTemplates }) => {
        if (!orderTemplates || !orderTemplates.addToBasket)
          return of(orderTemplatesAdded(0), basketChangeCompleted(0));

        const { templatesAmount, linesAmount, modifiedLines } = orderTemplates.addToBasket;
        const actions = [orderTemplatesAdded(templatesAmount), basketChangeCompleted(linesAmount)];
        const settings = state$.value.settings;
        const routeData = state$.value.routing.previous?.routeData;
        const routeName = routeData?.routeName;

        const addedProducts = modifiedLines ? getProductsTrackingDataFromLines(modifiedLines.list) : [];
        if (addedProducts && addedProducts.length) {
          actions.push(trackAddToBasket(addedProducts));
        }

        if (routeName === RouteName.BasketPage) {
          actions.push(navigateTo(routeData));
        } else if (settings.basket.redirectOnAdd) {
          actions.push(navigateTo(routesBuilder.forBasket()));
        }

        return from(actions);
      }),
      retryWithToast(action$, logger),
      concatToIfEmpty(of(orderTemplatesAdded(0), basketChangeCompleted(0))),
      takeUntil(locationChanged$),
      startWith(basketChangeStarted()),
    )),
  );
};

const orderTemplatesRemoveEpic = (action$, _state$, { api, logger }) => {
  const locationChanged$ = action$.pipe(ofType(LOCATION_CHANGED));

  return action$.pipe(
    ofType(ORDER_TEMPLATES_REMOVAL_REQUESTED),
    mergeMap(action => api.graphApi(removeTemplatesMutation, action.payload).pipe(
      map(() => orderTemplatesRemoved(action.payload.ids)),
      retryWithToast(action$, logger),
      takeUntil(locationChanged$),
    )),
  );
};

const saveOrderTemplateEpic = (action$, _state$, { api, console }) => {
  const locationChanged$ = action$.pipe(ofType(LOCATION_CHANGED));

  return action$.pipe(
    ofType(ORDER_TEMPLATE_SAVE),
    mergeMap(action => api.graphApi(saveTemplateMutation, action.payload).pipe(
      mergeMap(({ orderTemplates }) => of(
        orderTemplateSavingResultReceived(orderTemplates && orderTemplates.save),
        unlockForm(FormLockKeys.SaveOrderTemplate)),
      ),
      retryWithToast(action$, console, _ => of(unlockForm(FormLockKeys.SaveOrderTemplate))),
      takeUntil(locationChanged$),
    )),
  );
};

export default combineEpics(
  loadOrderTemplatesEpic,
  loadOrderTemplateLinesEpic,
  addToBasketEpic,
  orderTemplatesRemoveEpic,
  saveOrderTemplateEpic,
);

function isTrackingEnabled(state$) {
  return state$.value.analytics && state$.value.analytics.isTrackingEnabled;
}
