import styles from '../../Order.module.scss';
import PropTypes from 'prop-types';
import { useSanaTexts } from 'components/sanaText';
import IdLink from './IdLink';

const SalesAgreementId = ({ agreementId }) => {
  const { texts: [salesAgreementId] } = useSanaTexts(['SalesAgreementId']);
  return (
    <span className={styles.agreementLineId}>
      {salesAgreementId}
      {<IdLink agreementId={agreementId} />}
    </span>
  );
};

SalesAgreementId.propTypes = {
  agreementId: PropTypes.string.isRequired,
};

export default SalesAgreementId;
