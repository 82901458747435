import styles from './PLP.module.scss';
import { memo } from 'react';
import PropTypes from 'prop-types';
import { connectToContext } from 'utils/react';
import ProductContext from './ProductContext';
import { ProductStock } from 'components/primitives/product';
import { Placeholder } from 'components/primitives/placeholders';
import { useIsPreview } from 'components/objects/preview';
import { useHasAbilities } from 'components/objects/user';
import { AbilityTo } from 'behavior/user/constants';

const Stock = ({ stockLevels, inventory }) => {
  const canViewStock = useHasAbilities(AbilityTo.ViewStock)[0];
  const isPreview = useIsPreview();

  if (!canViewStock)
    return null;

  if (inventory === null || stockLevels == null) {
    if (isPreview) {
      return (
        <span className={styles.stockIndication}>
          <Placeholder className={styles.stockPreviewPlaceholder} />
        </span>
      );
    }

    return null;
  }

  return (
    <span className={styles.stockIndication}>
      <ProductStock inventory={inventory} stockLevels={stockLevels} />
    </span>
  );
};

Stock.propTypes = {
  inventory: PropTypes.number,
  stockLevels: PropTypes.shape({
    outOfStock: PropTypes.number.isRequired,
    lowStock: PropTypes.number.isRequired,
    maxStockNumber: PropTypes.number,
  }),
};

export default connectToContext(
  [ProductContext],
  ({ stockLevels, inventory }) => ({ stockLevels, inventory }),
)(
  memo(Stock),
);