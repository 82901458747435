import { stripHtmlTagsAndFormatting } from 'utils/helpers';
import renderHTML from './renderHTML';
import replacementTransformer from './replacementTransformer';
import { formatText } from 'utils/formatting';
import { removeInsiteEditorEncoding } from 'components/visualDesigner/insiteEditor';
import type { ReactNode, ReactText } from 'react';

type Text = string | null

export function makeSimpleText(text: Text): Text;
export function makeSimpleText(text: Text, formatWith: ReactText[], formatAsHtml: false): Text;
export function makeSimpleText(text: Text, formatWith: ReactNode[], formatAsHtml: true): ReactNode | null;
export function makeSimpleText(text: Text, formatWith?: ReactNode[], formatAsHtml = false): ReactNode {
  if (!text)
    return text;

  const simpleText = stripHtmlTagsAndFormatting(text);
  if (!formatWith || !formatWith.length)
    return renderHTML(simpleText)[0];

  if (formatAsHtml)
    return makeRichText(simpleText, getDecodedFormatWith(formatWith));

  const textArguments = getDecodedFormatWith(formatWith) as ReactText[];
  encodeArguments(textArguments);

  const result = renderHTML(formatText(simpleText, textArguments) as string);
  return result[0];
}

export function makeRichText(text: string | null | undefined, formatWith?: ReactNode[]): ReactNode {
  if (!text)
    return text;

  if (!formatWith || !formatWith.length)
    return renderHTML(text);

  const decodedFormatWith = getDecodedFormatWith(formatWith);
  return renderHTML(text, replacementTransformer(decodedFormatWith));
}

const replacements = new Map<string, string>([['<', '&lt;'], ['&', '&amp;']]);
function encodeArguments(args: ReactText[]) {
  for (let i = 0; i < args.length; i++) {
    const argument = args[i];
    if (typeof argument === 'string')
      args[i] = argument.replace(/[<&]/g, s => replacements.get(s) || s);
  }
}

function getDecodedFormatWith(args: ReactNode[]): ReactNode[] {
  return args.map(arg => {
    if (typeof arg === 'string')
      return removeInsiteEditorEncoding(arg);

    return arg;
  });
}
