import PropTypes from 'prop-types';
import { MediaGallery } from 'components/objects/mediaGallery';
import { connect } from 'react-redux';
import { connectToContext } from 'utils/react';
import ProductContext from './ProductContext';
import { iEquals } from 'utils/helpers';

const ProductGallery = ({ id, title, images, variantId, noImageLarge, variantComponents }) => {
  let index = -1;

  const itemsNumber = images.length;
  for (let i = 0; i < itemsNumber; i++) {
    const image = images[i];
    if (image.title == null)
      image.title = title;

    if (index === -1 && image.variantId && iEquals(image.variantId, variantId))
      index = i;
  }

  if (index === -1 && variantComponents != null) {
    const componentValues = Object.values(variantComponents);
    if (componentValues.length)
      index = images.findIndex(image => componentValues.some(v => iEquals(v.id, image.variantId)));
  }

  return <MediaGallery key={id} items={images} noImageLarge={noImageLarge} index={index} />;
};

ProductGallery.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string,
  images: PropTypes.array.isRequired,
  variantId: PropTypes.string,
  noImageLarge: PropTypes.string,
  variantComponents: PropTypes.object,
};

export default connectToContext(
  [ProductContext],
  ({
    product: { id, title, images },
    variantId,
    variantComponents,
  }) => ({ id, title, images, variantId, variantComponents }),
)(
  connect(({ settings: { product } }) => ({ noImageLarge: product && product.noImage.large }))(ProductGallery),
);
