import { SystemRouteData, urlsBuilder } from 'routes';
import getLanguageSegment from './getLanguageSegment';
import { of } from 'rxjs';
import { first, map } from 'rxjs/operators';
import type { AppState } from 'behavior';
import type { StateObservable } from 'redux-observable';

export default function requestRoute(route: SystemRouteData, state$: StateObservable<AppState>) {
  const state = state$.value;
  const languageSegment = getLanguageSegment(state);

  if (languageSegment !== undefined && state.settings.url) {
    const builder = urlsBuilder({ languageSegment, idEncodings: state.settings.url.idEncodings });
    return of(builder.getUrlForRoute(route));
  }

  return state$.pipe(
    map(s => ({
      languageSegment: getLanguageSegment(s),
      idEncodings: s.settings.url?.idEncodings,
    })),
    first(options => options.languageSegment !== undefined && !!options.idEncodings),
    map(options => urlsBuilder(options as Parameters<typeof urlsBuilder>[0]).getUrlForRoute(route)),
  );
}
