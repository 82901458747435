import styles from './MyAccount.module.scss';
import btnStyles from 'components/primitives/buttons/Button.module.scss';
import PropTypes from 'prop-types';
import { RichText, SimpleText } from 'components/sanaText';
import { LinkButton } from 'components/primitives/links';
import { routesBuilder } from 'routes';

const MainBlockItems = ({ canOrderProducts, userName }) => (
  <div className={`${styles.mainBlock} ${!canOrderProducts ? styles.noOrderProducts : ''} clearfix`}>
    <div className={styles.topActions}>
      {canOrderProducts &&
        <LinkButton
          className={`${btnStyles.btnSmall} ${btnStyles.btnPlus}`}
          to={routesBuilder.forBasket()}
        >
          <SimpleText textKey="NewOrder" />
        </LinkButton>
      }
    </div>
    <div className={styles.mainInfo}>
      <p className={styles.accountName}><SimpleText textKey="Hello" /> {userName}</p>
      <div className={styles.description}><RichText textKey="MyAccount_Description" /></div>
    </div>
  </div>
);

MainBlockItems.propTypes = {
  canOrderProducts: PropTypes.bool.isRequired,
  userName: PropTypes.string,
};

export default MainBlockItems;