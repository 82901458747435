import styles from '../Checkout.module.scss';
import { useRef, useState, useContext, useCallback } from 'react';
import PropTypes from 'prop-types';
import { SimpleText, RichText, UseSanaTexts } from 'components/sanaText';
import { useIsMobileViewport, useOnLanguageChanged, useOnChange } from 'utils/hooks';
import BasketFooter from '../../basket/footer';
import MobileOverviewLines from './MobileOverviewLines';
import OverviewLines from './OverviewLines';
import SubmitRow from './SubmitRow';
import LoadingIndicator from './StepLoadingIndicator';
import { useSelector, shallowEqual } from 'react-redux';
import { InfoAlert } from 'components/primitives/alerts';
import { CustomerTypes } from 'behavior/user';
import AdditionalInfoForm from './AdditionalInfoForm';
import { isVisible } from './constants';
import { Steps } from 'behavior/pages/checkout';
import { useDispatch } from 'react-redux';
import { saveAdditionalInfo } from 'behavior/pages/checkout';
import TermsAndAgreements from './termsAndAgreements';
import SummaryInfo from './summary/SummaryInfo';
import { makeRichText } from 'utils/render';
import { OrderAuthorizers } from 'components/primitives/order';
import StepDoneMark from './StepDoneMark';

const OverviewStep = ({ className, isQuote, children, isCompleted }) => (
  <section className={className}>
    <div className={styles.header}>
      <h2>
        <SimpleText textKey={isQuote
          ? 'CheckoutStep_QuoteOverview'
          : 'CheckoutStep_OrderOverview'}
        />
      </h2>
      {isCompleted && <StepDoneMark />}
    </div>
    {children &&
      <div className={styles.body}>
        {children}
      </div>
    }
  </section>
);

OverviewStep.propTypes = {
  className: PropTypes.string.isRequired,
  isQuote: PropTypes.bool,
  isCompleted: PropTypes.bool,
  children: PropTypes.node,
};

export default OverviewStep;

// eslint-disable-next-line react/no-multi-comp
export const OverviewStepBody = ({
  info,
  context,
  extraButton,
  summaryOnMobileOnly,
  showFooter,
  saveOnSubmitOnly,
  additionalInfoFormikRef,
  termsAndAgreementsFormikRef,
  onAdditionalInfoBlur,
  showSalesAgreement = false,
}) => {
  const isMobile = useIsMobileViewport();
  const { submit, setLoading } = useContext(context);
  let [isSubmitDisabled, setSubmitDisabled] = useState(null);

  const isOffline = useSelector(state => state.app.offlineMode);

  const Lines = isMobile ? MobileOverviewLines : OverviewLines;
  const linesLoaded = !!info.productLines.list;

  // Use useRef to remember initial state of loaded/non-loaded lines.
  showFooter = showFooter || useRef(!linesLoaded).current;
  const collapseState = useState(showFooter);
  useOnLanguageChanged(() => collapseState[1](showFooter));

  const msgKey = useSelector(selectMessageKey);
  const additionalFieldVisibilities = useSelector(selectAdditionalFieldsVisibilities, shallowEqual),
    [commentVisibility, deliveryDateVisibility, referenceNoVisibility] = additionalFieldVisibilities;

  const showAdditionalInfo = additionalFieldVisibilities.some(isVisible);

  if (additionalInfoFormikRef && !showAdditionalInfo)
    additionalInfoFormikRef.current = null;

  useOnChange(() => {
    isSubmitDisabled = false;
    setSubmitDisabled(false);

    if (!linesLoaded && !collapseState[0])
      collapseState[1](true);
  }, [info], false);

  const onSubmit = useCallback(() => {
    submit(saveOnSubmitOnly ? additionalInfoFormikRef : null, termsAndAgreementsFormikRef).then(valid => {
      if (valid) {
        setLoading(Steps.Overview);
        setSubmitDisabled(true);
      }
    });
  }, [saveOnSubmitOnly, submit, setLoading]);

  const dispatch = useDispatch();

  const onAdditionalInfoSubmit = useCallback(info => {
    if (saveOnSubmitOnly) {
      onSubmit();
    } else {
      dispatch(saveAdditionalInfo(info));
      setLoading();
    }
  }, [onSubmit]);

  const submitButtonTextKey = getSubmitButtonTextKey(info.isQuote, !!info.paymentMethods, isOffline);
  const submitRow = (
    <SubmitRow
      isQuote={info.isQuote}
      submitButtonTextKey={submitButtonTextKey}
      onSubmit={onSubmit}
      extraButton={extraButton}
      disabled={isSubmitDisabled}
    />
  );

  const showShipping = !!info.shippingMethods;
  const showPayment = !isOffline && !!info.paymentMethods;
  const showExtraPayment = !isOffline && !!info.extraPaymentStep?.summaryLine;

  return (
    <>
      {msgKey &&
        <UseSanaTexts options={[msgKey]}>
          {renderMessage}
        </UseSanaTexts>
      }
      {(!summaryOnMobileOnly || isMobile) && (
        <SummaryInfo
          info={info}
          showBillingAddress
          showShippingAddress
          showEmailAddress={summaryOnMobileOnly}
          showChangeLinks={summaryOnMobileOnly}
          showShipping={showShipping}
          showPayment={showPayment}
          showExtraPayment={showExtraPayment}
        />
      )}
      {submitRow}
      <Lines
        productLines={info.productLines}
        serviceLines={info.serviceLines}
        linesLoaded={linesLoaded}
        collapseState={collapseState}
        showFooter={showFooter}
      />
      <BasketFooter
        {...BasketFooter.selectBasketProps(info)}
        readOnly
        className={styles.totals}
        showSalesAgreement={showSalesAgreement}
      />
      {showAdditionalInfo &&
        <AdditionalInfoForm initialValues={info.additionalInfo}
          context={context}
          commentVisibility={commentVisibility}
          deliveryDateVisibility={deliveryDateVisibility}
          referenceNoVisibility={referenceNoVisibility}
          formikRef={additionalInfoFormikRef}
          submitOnBlur={!saveOnSubmitOnly}
          onSubmit={onAdditionalInfoSubmit}
          onBlur={onAdditionalInfoBlur}
        />
      }
      <OrderAuthorizers authorizers={info.authorizers} className={styles.authorizers} />
      {!!info.quote &&
        <InfoAlert>
          <RichText
            textKey="QuotePromotionOverview_ConvertationWillBeFinished"
            formatWith={[<SimpleText textKey={'ButtonText_' + submitButtonTextKey} />]}
          />
        </InfoAlert>
      }
      <TermsAndAgreements
        submitButtonTextKey={submitButtonTextKey}
        formikRef={termsAndAgreementsFormikRef}
        onSubmit={onSubmit}
      />
      {submitRow}
      <LoadingIndicator />
    </>
  );
};

OverviewStepBody.propTypes = {
  info: PropTypes.shape({
    productLines: PropTypes.shape({
      list: PropTypes.any,
    }).isRequired,
    serviceLines: PropTypes.any,
    isQuote: PropTypes.bool,
    shippingMethods: PropTypes.array,
    paymentMethods: PropTypes.array,
    extraPaymentStep: PropTypes.shape({
      summaryLine: PropTypes.string,
    }),
    quote: PropTypes.object,
  }).isRequired,
  context: PropTypes.object.isRequired,
  extraButton: PropTypes.node,
  summaryOnMobileOnly: PropTypes.bool,
  showFooter: PropTypes.bool,
  hideAdditionalInfo: PropTypes.bool,
  saveOnSubmitOnly: PropTypes.bool,
  additionalInfoFormikRef: PropTypes.object,
  termsAndAgreementsFormikRef: PropTypes.object,
  showSalesAgreement: PropTypes.bool,
  onAdditionalInfoBlur: PropTypes.func,
};

function selectMessageKey(state) {
  if (!state.app.offlineMode || !state.settings.loaded)
    return null;

  return state.user.customerType === CustomerTypes.B2C
    ? state.settings.checkout.enableOfflinePrices && 'Checkout_OrderOverview_OfflineMessage_B2C_Prices'
    : (state.settings.checkout.enableOfflinePrices ? 'Checkout_OrderOverview_OfflineMessage_B2B_Prices' : 'Checkout_OrderOverview_OfflineMessage_B2B_NoPrices');
}

function selectAdditionalFieldsVisibilities(state) {
  if (!state.settings.loaded)
    return [null, null, null];

  const checkout = state.settings.checkout;
  return [checkout.commentVisibility, checkout.deliveryDateVisibility, checkout.referenceNoVisibility];
}

// eslint-disable-next-line react/no-multi-comp
const renderMessage = ([msg]) => <InfoAlert>{makeRichText(msg)}</InfoAlert>;

export function getSubmitButtonTextKey(isQuote, isPaidOnline, isOfflineMode) {
  if (isQuote)
    return 'GetQuote';

  if (isOfflineMode)
    return 'SubmitOfflineOrder';

  return isPaidOnline ? 'Pay' : 'SubmitOrder';
}
