exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Modal_modal{position:fixed;top:0;left:0;z-index:9300000;width:100%;height:100%;padding:10px 0}.Modal_modal.Modal_fullscreen{padding:0}.Modal_modal.Modal_fullscreen .Modal_middle{width:100%}.Modal_modal.Modal_fullscreen .Modal_content{min-height:100vh;width:100%}.Modal_modal.Modal_hidden{display:none}.Modal_body{display:flex;justify-content:center;align-items:center}.Modal_body,.Modal_overlay{height:100%;width:100%}.Modal_overlay{background-color:var(--popup_Overlay_BackgroundColor,#000);opacity:.2;position:fixed;top:0;left:0}.Modal_middle{position:relative;max-width:100%;max-height:100%;border:1px solid var(--popup_BorderColor,#e6e6e6);border-radius:3px;background:var(--popup_BackgroundColor,#fff)}.Modal_middle:focus{outline:none}.Modal_middle:before{content:\"\";position:absolute;top:0;bottom:0;right:0;left:0;box-shadow:0 0 10px 0 var(--popup_Overlay_BackgroundColor,#000);opacity:.5;pointer-events:none;border-radius:3px}.Modal_content{position:relative;z-index:1;text-align:left;padding:24px 36px 24px 32px;background:var(--popup_BackgroundColor,#fff);max-width:100%;max-height:calc(100vh - 20px);border-radius:3px;width:592px;overflow-y:auto}.Modal_content.Modal_content-medium-width{width:1008px}.Modal_content.Modal_content-large-width{width:1030px}.Modal_close-btn-wrapper{position:absolute;top:8px;right:12px;z-index:2}.Modal_close-btn{width:24px;height:24px;padding:0;border:none;background:none;cursor:pointer}.Modal_close-btn img{max-width:24px;max-height:24px}.Modal_close-btn svg{fill:#2f2f33;width:24px;height:24px}.Modal_title{margin:0;padding:0 24px 13px 0}@media (-ms-high-contrast:active),(-ms-high-contrast:none){.Modal_body{flex-direction:column}.Modal_middle{min-height:1px}}", ""]);

// exports
exports.locals = {
	"modal": "Modal_modal",
	"fullscreen": "Modal_fullscreen",
	"middle": "Modal_middle",
	"content": "Modal_content",
	"hidden": "Modal_hidden",
	"body": "Modal_body",
	"overlay": "Modal_overlay",
	"content-medium-width": "Modal_content-medium-width",
	"contentMediumWidth": "Modal_content-medium-width",
	"content-large-width": "Modal_content-large-width",
	"contentLargeWidth": "Modal_content-large-width",
	"close-btn-wrapper": "Modal_close-btn-wrapper",
	"closeBtnWrapper": "Modal_close-btn-wrapper",
	"close-btn": "Modal_close-btn",
	"closeBtn": "Modal_close-btn",
	"title": "Modal_title"
};