import styles from '../../Order.module.scss';
import PropTypes from 'prop-types';
import { Link } from 'components/primitives/links';
import { routesBuilder } from 'routes';

const TermLink = ({ agreementId, salesAgreementLineId }) => {
  const route = routesBuilder.forSalesAgreement(agreementId, salesAgreementLineId);
  return <Link className={styles.agreementIdLink} to={route}>{salesAgreementLineId}</Link>;
};

TermLink.propTypes = {
  agreementId: PropTypes.string.isRequired,
  salesAgreementLineId: PropTypes.string.isRequired,
};

export default TermLink;