import styles from '../Checkout.module.scss';
import { useContext, useEffect, useCallback, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import CheckoutContext from './CheckoutContext';
import { Steps, selectShippingMethod } from 'behavior/pages/checkout';
import { ShippingMethodStep as ShippingMethodStepBase, ShippingMethodStepBody } from '../base/shipping';
import { useSanaTexts } from 'components/sanaText';
import scrollIntoView from 'scroll-into-view';

const ShippingMethodStep = ({ info, isCompleted }) => {
  const dispatch = useDispatch();
  const { setLoading, registerStep } = useContext(CheckoutContext);
  const methodSelectedRef = useRef(!!info.shippingMethodId);
  const noMethodsRef = useRef();
  noMethodsRef.current = info.shippingMethods.length === 0;

  const [showValidation, setShowValidation] = useState();

  useEffect(() => {
    const selectedShippingMethodAvailable = info.shippingMethods?.some(m => m.id === info.shippingMethodId);
    if (selectedShippingMethodAvailable)
      setShowValidation(false);

    methodSelectedRef.current = selectedShippingMethodAvailable;
  }, [info.shippingMethodId, info.shippingMethods]);

  useEffect(() => {
    const validateStep = () => {
      if (methodSelectedRef.current || noMethodsRef.current)
        return true;

      setShowValidation(true);
      scrollIntoView(document.querySelector(`.${styles.validation}`), { time: 200 });
      return false;
    };

    registerStep(Steps.Shipping, styles.shippingMethod, [Steps.Address, Steps.Shipping], validateStep);
  }, []);

  const onSelect = useCallback((id, locationId = null) => {
    if (id === info.shippingMethodId && info.pickupLocation?.id === locationId)
      return;

    if (!locationId && info.shippingMethods.find(m => m.id === id).hasLocations)
      return;

    setShowValidation(false);
    dispatch(selectShippingMethod(id, locationId));
    setLoading(Steps.Shipping);
  }, [info.shippingMethods, info.shippingMethodId, info.pickupLocation]);

  const [validationMsg] = useSanaTexts(['ShippingMethods_ValidationMessage']).texts;

  return (
    <ShippingMethodStepBase
      className={styles.shippingMethod}
      isQuote={info.isQuote}
      isCompleted={isCompleted}
    >
      <ShippingMethodStepBody
        shippingMethods={info.shippingMethods}
        selectedMethodId={info.shippingMethodId}
        selectedLocationId={info.pickupLocation?.id}
        locations={info.pickupLocations}
        isQuote={info.isQuote}
        totalGrossWeight={info.totalGrossWeight}
        onSelect={onSelect}
        validationMsg={showValidation && validationMsg}
      />
    </ShippingMethodStepBase>
  );
};

ShippingMethodStep.propTypes = {
  info: PropTypes.shape({
    isQuote: PropTypes.bool,
    totalGrossWeight: PropTypes.number,
    shippingMethods: PropTypes.array,
    selectedMethodId: PropTypes.string,
  }).isRequired,
  isCompleted: PropTypes.bool,
};

export default ShippingMethodStep;
