export const ADMIN_IMPERSONATION_STOP = 'ADMIN_IMPERSONATION_STOP' as const;
export const stop = (isLogout?: boolean) => ({
  type: ADMIN_IMPERSONATION_STOP,
  payload: { isLogout },
});

export const ADMIN_IMPERSONATION_STOPPED = 'ADMIN_IMPERSONATION_STOPPED' as const;
export const notifyStopped = (isLogout?: boolean) => ({
  type: ADMIN_IMPERSONATION_STOPPED,
  payload: { isLogout },
});

export type ImpersonationAction = ReturnType<
  | typeof stop
  | typeof notifyStopped
>