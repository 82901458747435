import { AgreementLinesDropdown } from 'components/objects/product/salesAgreements';
import { useCanViewUom } from 'components/primitives/product';
import { useUpdateContext } from '../updateContext';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

const AgreementSelector = ({
  agreementLines,
  selectedLineId,
  basketLineId,
  basketLineUomId,
  allowUOMSelection,
}) => {
  const canViewUom = useCanViewUom();

  const { setLineAgreement, deleteLineAgreement } = useUpdateContext();

  let linesToDisplay = agreementLines;
  if (!allowUOMSelection || !canViewUom) {
    linesToDisplay = linesToDisplay.filter(line => !line.uom || line.uom?.id.toUpperCase() === basketLineUomId.toUpperCase());
  }

  return (
    <AgreementLinesDropdown
      agreementLines={linesToDisplay}
      selectedLineId={selectedLineId}
      canViewUom={canViewUom}
      onChange={lineId => {
        lineId ? setLineAgreement(basketLineId, lineId) : deleteLineAgreement(basketLineId);
      }}
    />
  );
};

AgreementSelector.propTypes = {
  agreementLines: PropTypes.array,
  selectedLineId: PropTypes.string,
  basketLineId: PropTypes.string,
  basketLineUomId: PropTypes.string,
  allowUOMSelection: PropTypes.bool.isRequired,
};

export default connect(
  ({ settings: { product: { allowUOMSelection } } }) => ({
    allowUOMSelection,
  }),
)(AgreementSelector);
