import styles from './WishList.module.scss';
import { SimpleText } from 'components/sanaText';
import { Container } from 'components/primitives/grid';
import { PageComponentNames } from 'behavior/pages';
import WishList from './WishList';
import Breadcrumb from './Breadcrumb';
import { PageContent } from 'components/objects/pages';

function wishListPageRenderer(page) {
  return (
    <>
      <Container>
        <Breadcrumb />
        <h1 className={styles.title}><SimpleText textKey="WishList_Title" /></h1>
      </Container>
      <PageContent content={page.content?.header} position="header" />
      <Container>
        <WishList data={page} />
      </Container>
      <PageContent content={page.content?.footer} position="footer" />
    </>
  );
}

export default {
  [PageComponentNames.WishList]: wishListPageRenderer,
};