import styles from '../Checkout.module.scss';
import { memo, useCallback, useMemo, useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import AddressStep from './AddressStep';
import GuestAddressStep from './GuestAddressStep';
import OverviewStep from './OverviewStep';
import ShippingMethodStep from './ShippingMethodStep';
import { CheckoutProvider } from './CheckoutProvider';
import IncompleteStepsMessage from '../base/IncompleteStepsMessage';
import PaymentMethodStep from './PaymentMethodStep';
import CustomerDataStep from './CustomerDataStep';
import ExtraPaymentStep from './ExtraPaymentStep';
import { useSelector, connect } from 'react-redux';
import { Steps } from 'behavior/pages/checkout';
import { useIncompleteStepsInfo } from '../hooks';
import { identity } from 'rxjs';
//TICKET 104230
import { useHasAbilities } from 'components/objects/user';
import { AbilityTo } from 'behavior/user/constants';
import { RichText } from '../../../sanaText';
import { HomeButton } from 'components/objects/checkout/base/HomeButton';
import CheckoutLogoutButton from 'components/objects/checkout/base/CheckoutLogoutButton';
import { logout } from 'behavior/user';
//TICKET 104230

const Checkout = ({ info, logout }) => {
  const isOffline = useSelector(state => state.app.offlineMode);

  const showShippingMethods = !!info.shippingMethods,
    showPaymentMethods = !isOffline && !!info.paymentMethods,
    showMethods = showShippingMethods || showPaymentMethods,
    showExtraPayment = showPaymentMethods && !!info.extraPaymentStep,
    showCustomerData = showPaymentMethods && !!info.customerDataStep;

  const incompleteSteps = useIncompleteStepsInfo(info.steps);

  const [incompleteClientSteps, setIncompleteClientSteps] = useState({});
  const setStepCompletion = useCallback((id, completed) => {
    setIncompleteClientSteps(state => {
      if (!completed && !state[id])
        return { ...state, [id]: true };

      if (completed && state[id]) {
        const result = { ...state };

        delete result[id];
        return result;
      }

      return state;
    });
  }, []);

  const addressCompleted = !incompleteClientSteps[Steps.Address] && !incompleteSteps.has(Steps.Address),
    shippingStepCompleted = !incompleteClientSteps[Steps.Shipping] && !incompleteSteps.has(Steps.Shipping),
    paymentStepCompleted = !incompleteClientSteps[Steps.Payment] && !incompleteSteps.has(Steps.Payment),
    customerDataStepCompleted = !incompleteClientSteps[Steps.CustomerData] && !incompleteSteps.has(Steps.CustomerData),
    extraPaymentStepCompleted = !incompleteClientSteps[Steps.ExtraPayment] && !incompleteSteps.has(Steps.ExtraPayment),
    overviewStepCompleted = !incompleteClientSteps[Steps.Overview] && incompleteSteps.size === 0;

  const allStepsCompleted = useMemo(() => {
    return [
      addressCompleted,
      shippingStepCompleted,
      paymentStepCompleted,
      customerDataStepCompleted,
      extraPaymentStepCompleted,
      overviewStepCompleted,
    ].every(identity);
  }, [
    addressCompleted,
    shippingStepCompleted,
    paymentStepCompleted,
    customerDataStepCompleted,
    extraPaymentStepCompleted,
    overviewStepCompleted,
  ]);
  //TICKET 104230
  const OCIPunschOutAbility = useHasAbilities(AbilityTo.OCIPunschOutAbility)[0];
  const ociForm = useRef();
  useEffect(() => {
    if (OCIPunschOutAbility) {
      logout();
      ociForm.current.submit();
    }
  }, []);
  //TICKET 104230

  return (
    <>
      {OCIPunschOutAbility &&
        <div className="basket-new">
          <div><RichText textKey="Checkout_OCIPunchout_Description" /> </div>
          <div className="Link_arrowed"> <HomeButton /></div>
          <div>   <CheckoutLogoutButton />   </div>
          <form method="POST" name="BASKET" id="BASKET" action={info.oCIHookUrl}  style={{ display: 'none' }} ref={ociForm}>
            {info.oCIBaksket.map(function (node, index) {
              return <input type="hidden" id={node.name} name={node.name} value={node.value ? node.value : ''} key={index} />;
            })}
          </form>
{/*          <iframe name="test" id="test" title="Test" style={{ display: 'none' }} />*/}
        </div>
      }
      {!OCIPunschOutAbility &&
        <CheckoutProvider info={info} setStepCompletion={setStepCompletion}>
          <IncompleteStepsMessage incompleteSteps={info.incompleteSteps} />
          <div className={`${styles.steps} ${showMethods ? '' : styles.noMethods} ${styles.oneStep}`}>
            {info.isGuest
              ?
              <GuestAddressStep info={info} isCompleted={addressCompleted} /> :
              <AddressStep info={info} isCompleted={addressCompleted} />
            }
            {showMethods &&
              <div className={styles.methods}>
                {showShippingMethods && (
                  <ShippingMethodStep
                    info={info}
                    isCompleted={shippingStepCompleted}
                  />
                )}
                {showPaymentMethods && (
                  <PaymentMethodStep
                    info={info}
                    isCompleted={paymentStepCompleted}
                  />
                )}
                {showCustomerData && (
                  <CustomerDataStep
                    customerDataStep={info.customerDataStep}
                    isCompleted={customerDataStepCompleted}
                  />
                )}
                {showExtraPayment && (
                  <ExtraPaymentStep
                    extraPaymentStep={info.extraPaymentStep}
                    isCompleted={extraPaymentStepCompleted}
                  />
                )}
              </div>
            }
            <OverviewStep
              className={styles.overview}
              info={info}
              isCompleted={allStepsCompleted}
            />
          </div>
        </CheckoutProvider>
      }
    </>
  );
};

Checkout.propTypes = {
  info: PropTypes.shape({
    shippingAddresses: PropTypes.array,
    billingAddress: PropTypes.object,
    shippingAddress: PropTypes.object,
    shippingTemplateFields: PropTypes.array,
    shippingMethods: PropTypes.array,
    paymentMethods: PropTypes.array,
    customerDataStep: PropTypes.object,
    extraPaymentStep: PropTypes.object,
    incompleteSteps: PropTypes.array,
    isQuote: PropTypes.bool,
  }).isRequired,
};

export default memo(connect(undefined, { logout })(Checkout));

