import Field, { fieldPropTypes } from '../Field';
import { Checkbox } from 'components/primitives/form';

const CheckboxField = props => (
  <Field
    type="checkbox"
    initialValue={false}
    as={Checkbox}
    {...props}
  />
);

CheckboxField.propTypes = fieldPropTypes;

export default CheckboxField;
