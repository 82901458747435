import { memo, useRef, useContext, useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import CheckoutContext from './CheckoutContext';
import StepButtons from './StepButtons';
import {
  CustomerDataStep as CustomerDataStepBase,
  CustomerDataStepBody,
} from '../base/payment';
import { useDispatch } from 'react-redux';
import { saveCustomerData } from 'behavior/pages/checkout';
import { setLoadingIndicator, unsetLoadingIndicator } from 'behavior/loadingIndicator';
import { scrollToInvalidElement } from './helpers';

const CustomerDataStep = ({ customerDataStep, asLink, isActive, isPromotion, isCompleted, className }) => {
  const dispatch = useDispatch();
  const { nextStep, setLoading, redirected } = useContext(CheckoutContext);
  const formRef = useRef();
  const savingDataRef = useRef();
  const [showValidation, setShowValidation] = useState();

  useEffect(() => {
    setShowValidation(isActive && redirected);
  }, [isActive, redirected]);

  const onStepSubmit = useCallback(async () => {
    if (!(await formRef.current.validate())) {
      setShowValidation(true);
      scrollToInvalidElement();
      return;
    }

    submitStep();
  }, []);

  useEffect(() => {
    if (!savingDataRef.current)
      return;

    savingDataRef.current = false;
    if (customerDataStep.errors) {
      dispatch(unsetLoadingIndicator());
      formRef.current.errors = customerDataStep.errors;
      return;
    }

    nextStep(true);
  }, [customerDataStep]);

  const onFormValidate = valid => {
    if (valid)
      setShowValidation(false);
  };

  return (
    <CustomerDataStepBase
      asLink={asLink}
      className={className}
      isPromotion={isPromotion}
      isCompleted={isCompleted}
    >
      {isActive &&
        <>
          <CustomerDataStepBody
            customerDataStep={customerDataStep}
            formRef={formRef}
            onFormSubmit={onStepSubmit}
            setLoading={setLoading}
            showValidation={showValidation}
            onFormValidate={onFormValidate}
          />
          <StepButtons onStepSubmit={onStepSubmit} />
        </>
      }
    </CustomerDataStepBase>
  );

  function submitStep() {
    savingDataRef.current = true;
    dispatch(saveCustomerData(formRef.current.values));
    dispatch(setLoadingIndicator());
  }
};

CustomerDataStep.propTypes = {
  customerDataStep: PropTypes.shape({
    errors: PropTypes.arrayOf(PropTypes.shape({
      fieldName: PropTypes.string,
      message: PropTypes.string.isRequired,
    })),
  }).isRequired,
  asLink: PropTypes.bool,
  isActive: PropTypes.bool,
  isPromotion: PropTypes.bool,
  isCompleted: PropTypes.bool,
  className: PropTypes.string,
};

export default memo(CustomerDataStep);
