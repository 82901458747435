import { loadContentPageQuery } from './queries';
import { of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { pluckAsPage, initPageContentBlocks } from 'behavior/pages/helpers';
import { PageComponentNames } from '../componentNames';

export default ({ params: { id } }, _state$, { api }) => id
  ? api.graphApi(loadContentPageQuery, { id }).pipe(
    pluckAsPage('content', PageComponentNames.Content),
    initPageContentBlocks('content'),
    tap(data => {
      if (!data)
        return;

      const { page } = data;
      const firstRowHeroSettings = page?.content[0]?.heroEffect;

      if (firstRowHeroSettings) {
        const { headerOnTop, headerTextColor, altSmallLogo, altLogo } = firstRowHeroSettings;
        page.layoutHeroEffect = { headerOnTop, headerTextColor, altSmallLogo, altLogo };
      }
    }),
  )
  : of(null);
