import basketStyles from './Basket.module.scss';
import linkStyles from 'components/primitives/links/Link.module.scss';
import { useHasAbilities } from 'components/objects/user';
import { AbilityTo } from 'behavior/user/constants';
import { Link } from 'components/primitives/links';
import { routesBuilder } from 'routes';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { applyAgreementTerms } from 'behavior/salesAgreements';
import { removeAgreement } from 'behavior/basket/actions';
import Tooltip from 'components/primitives/tooltip';
import { useSanaTexts } from 'components/sanaText';
import { makeSimpleText } from 'utils/render';
import { useModal } from 'components/objects/modals';
import ConfirmationModal from 'components/objects/profile/salesAgreements/ConfirmationModal';
import { joinClasses } from 'utils/helpers';

const SalesAgreementControl = ({ appliedSalesAgreementId, dispatch, isAgreementAppliedToLines, className }) => {
  const [
    canUseAgreement,
    canAssignAgreementLineAutomatically,
  ] = useHasAbilities(
    AbilityTo.UseSalesAgreements,
    AbilityTo.AssignAgreementLineAutomatically,
  );

  const { opened, show, hide } = useModal();
  const [
    agreementHasNotEffectToCurrentBasket,
    useSalesAgreement,
    salesAgreementLabel,
    remove,
    change,
    view,
    applyAgreementLinesAutomatically,
  ] =
    useSanaTexts(['AgreementHasNotEffectToCurrentBasket',
      'UseSalesAgreement',
      'SalesAgreementLabel',
      'ButtonText_Remove',
      'ButtonText_Change',
      'ViewDetails',
      'ApplyAgreementLinesAutomatically',
    ], makeSimpleText).texts;

  if (!canUseAgreement)
    return null;

  const handleRemoveClick = () => {
    if (isAgreementAppliedToLines)
      show();
    else
      cancel();
  };

  const cancel = () => dispatch(removeAgreement());
  const applyTermsAutomatically = () => dispatch(applyAgreementTerms());

  const tooltip = !isAgreementAppliedToLines
    && (
      <Tooltip
        id="greementNotApplied"
        title={salesAgreementLabel}
        body={agreementHasNotEffectToCurrentBasket}
      >
        <span />
      </Tooltip>);

  if (appliedSalesAgreementId)
    return (
      <div className={joinClasses(className, basketStyles.salesAgreementInfo)}>
        <div>
          {salesAgreementLabel}{tooltip}<span className={basketStyles.salesAgreementId}>{appliedSalesAgreementId}</span>
        </div>
        <div>
          <button type="button" className={`${linkStyles.link} ${basketStyles.remove}`} onClick={handleRemoveClick}>
            {remove}
          </button>
          <ConfirmationModal opened={opened} hide={hide} onConfirm={cancel} />
          <Link to={routesBuilder.forSalesAgreements} className={basketStyles.change}>
            {change}
          </Link>
          <Link to={routesBuilder.forSalesAgreement(appliedSalesAgreementId)} className={basketStyles.viewDetails}>
            {view}
          </Link>
          {canAssignAgreementLineAutomatically &&
            <button type="button" className={`${linkStyles.link} ${basketStyles.applyAgreementTermsAutomatically}`} onClick={applyTermsAutomatically}>
              {applyAgreementLinesAutomatically}
            </button>
          }
        </div>
      </div>
    );

  return (
    <div className={className}>
      <Link to={routesBuilder.forSalesAgreements} className={basketStyles.useAgreement}>
        {useSalesAgreement}
      </Link>
    </div>
  );
};

SalesAgreementControl.propTypes = {
  appliedSalesAgreementId: PropTypes.string,
  dispatch: PropTypes.func,
  isAgreementAppliedToLines: PropTypes.bool,
  className: PropTypes.string,
};

export default connect(({ basket: { salesAgreementInfo } }) => ({
  appliedSalesAgreementId: salesAgreementInfo?.id,
  isAgreementAppliedToLines: salesAgreementInfo?.isAppliedToLines,
}))(SalesAgreementControl);
