import styles from './OfflineMode.module.scss';
import linkStyles from 'components/primitives/links/Link.module.scss';
import PropTypes from 'prop-types';
import { Container } from 'components/primitives/grid';
import { Helmet } from 'react-helmet';
import { useSanaTexts } from 'components/sanaText';
import { connect } from 'react-redux';
import PromotionalText from 'components/objects/promotionalTexts/PromotionalText';
import { ShopAccountTypes } from 'behavior/user';
import SimpleText from 'components/sanaText/SimpleText';
import { Link } from 'components/primitives/links';
import { routesBuilder } from 'routes';
import { makeSimpleText } from 'utils/render';

const TemporaryNotAvailable = ({
  shopName,
  isAuthenticated,
  shopAccountType,
  isImpersonating,
}) => {
  const [title] = useSanaTexts(['ConnectionOfflinePage_PageTitle'], makeSimpleText).texts;

  return (
    <div id="unavailablePage">
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Container>
        <h1>{title}</h1>
        <div className={styles.message}>
          <PromotionalText textKey="ConnectionOffline_TemporaryNotAvailable" />
        </div>
        <span className={styles.shopName}>{shopName}</span>
        <ul className={styles.navs}>
          {isAuthenticated && (!isImpersonating || shopAccountType !== ShopAccountTypes.Contact) &&
          <li>
            <Link to={routesBuilder.forMyAccount} className={linkStyles.arrowed}>
              <SimpleText textKey="GotoAccountPage" />
            </Link>
          </li>
          }
          <li>
            <Link to={routesBuilder.forHome} className={linkStyles.arrowed}>
              <SimpleText textKey="GoToHomePage" />
            </Link>
          </li>
        </ul>
      </Container>
    </div>
  );
};

TemporaryNotAvailable.propTypes = {
  shopName: PropTypes.string,
  isAuthenticated: PropTypes.bool,
  shopAccountType: PropTypes.string,
  isImpersonating: PropTypes.bool,
};

export default connect(({
  settings: { shopName },
  user: { isAuthenticated, shopAccountType, isImpersonating },
}) => ({
  shopName,
  isAuthenticated,
  shopAccountType,
  isImpersonating,
}))(TemporaryNotAvailable);