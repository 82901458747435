exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".StickyPanel_is-sticky{position:fixed;left:0;right:0;z-index:1;background-color:var(--page_BackgroundColor,#fff);border-bottom:1px solid var(--tables_BorderColor,#e6e6e6)}.StickyPanel_is-fixed{position:absolute;top:auto!important;bottom:0;left:50%;width:calc(100vw - 17px);-webkit-transform:translateX(-50%);transform:translateX(-50%)}@media (pointer:coarse){.StickyPanel_is-fixed{width:100vw}}.StickyPanel_placeholder{\n  /*!*/}", ""]);

// exports
exports.locals = {
	"is-sticky": "StickyPanel_is-sticky",
	"isSticky": "StickyPanel_is-sticky",
	"is-fixed": "StickyPanel_is-fixed",
	"isFixed": "StickyPanel_is-fixed",
	"placeholder": "StickyPanel_placeholder"
};