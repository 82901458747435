import PropTypes from 'prop-types';
import { SimpleText } from 'components/sanaText';

const SalesAgreementLine = ({ className = '', salesAgreementId }) => {
  return (
    <tr className={className}>
      <th>
        <SimpleText textKey={'SalesAgreementId'} />
      </th>
      <td>
        {salesAgreementId}
      </td>
    </tr>
  );
};

SalesAgreementLine.propTypes = {
  salesAgreementId: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default SalesAgreementLine;
