import { useComputedValue } from 'utils/hooks';
import { getLanguageSegment } from 'behavior/route';
import { useSelector, shallowEqual } from 'react-redux';
import { urlsBuilder, SystemRouteData } from 'routes';

export default function useRoutes(routes: SystemRouteData[]) {
  const options = useSelector(s => ({
    languageSegment: getLanguageSegment(s),
    idEncodings: s.settings.url?.idEncodings,
  }), shallowEqual);

  return useComputedValue(() => {
    if (options.languageSegment === undefined || !options.idEncodings)
      return routes.map(_ => undefined);

    const builder = urlsBuilder(options as Parameters<typeof urlsBuilder>[0]);
    return routes.map(builder.getUrlForRoute);
  }, [routes, options]);
}
