import PropTypes from 'prop-types';
import ShoppingCartSuggestionsBase from './ShoppingCartSuggestionsBase';

const ShoppingCartSuggestions = ({ hideLabel, className }) => <ShoppingCartSuggestionsBase hideLabel={hideLabel} className={className} />;

ShoppingCartSuggestions.propTypes = {
  hideLabel: PropTypes.bool,
  className: PropTypes.string,
};

export default ShoppingCartSuggestions;
