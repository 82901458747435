import { useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { loadAlternateUrls } from 'behavior/localization';

export default () => {
  const dispatch = useDispatch();
  const { languages, alternateUrls, currentLanguage } = useSelector(state => state.localization);

  const selectedLanguage = languages.find(l => l.id === currentLanguage.id) ?? languages[0];
  const sortedLanguages = useMemo(() => [
    selectedLanguage, 
    ...languages.filter(language => language !== selectedLanguage),
  ], [languages, selectedLanguage]);

  return {
    selectedLanguage,
    sortedLanguages,
    alternateUrls,
    loadAlternateUrls: useCallback(() => {
      if (alternateUrls == null || !alternateUrls.length)
        dispatch(loadAlternateUrls());
    }, [alternateUrls]),
  };
};
