import { RouteName } from './RouteName';
import type { RouteData } from './types';

type BackTo = {
  url: string;
  routeData: RouteData;
}

export const routesBuilder = {
  forProduct: (productId: string, backTo?: BackTo, salesAgreementLineId?: string) => ({
    routeName: RouteName.ProductDetails as const,
    params: {
      id: productId,
      agreementLine: salesAgreementLineId,
    },
    options: backTo ? { backTo } : undefined,
  }),
  forBasket: (pageIndex?: number, linesOnly = false) => ({
    routeName: RouteName.BasketPage as const,
    params: pageIndex !== undefined ? { page: (pageIndex + 1).toString() } : undefined,
    options: { linesOnly },
  }),
  forProductList: (pageIndex: number, params: Record<string, any>) => ({
    routeName: RouteName.ProductList as const,
    params: { ...params, page: (pageIndex + 1).toString() },
  }),
  forProductsWithCategory: (categoryId: string, salesAgreementLineId?: string) => ({
    routeName: RouteName.ProductsWithCategory as const,
    params: {
      id: categoryId,
      agreementLine: salesAgreementLineId,
    },
  }),
  forSubAccount: (id: string) => ({
    routeName: RouteName.EditSubAccount as const,
    params: { id },
  }),
  forDocument: (id: string, documentType: string, originalOrderId?: string) => ({
    routeName: RouteName.DocumentDetails as const,
    params: { id, documentType, originalOrderId },
  }),
  forNotFound: () => ({ routeName: RouteName.NotFound as const }),
  forHome: () => ({ routeName: RouteName.Home as const }),
  forRepresent: () => ({ routeName: RouteName.Represent as const }),
  forLogin: () => ({ routeName: RouteName.Login as const }),
  forRegistration: () => ({ routeName: RouteName.Registration as const }),
  forMyAccount: () => ({ routeName: RouteName.MyAccount as const }),
  forNewSubAccount: () => ({ routeName: RouteName.NewSubAccount as const }),
  forEditProfile: (backTo?: BackTo) => ({
    routeName: RouteName.EditProfile as const,
    options: backTo ? { backTo } : undefined,
  }),
  forOrders: () => ({ routeName: RouteName.Orders as const }),
  forQuotes: () => ({ routeName: RouteName.Quotes as const }),
  forInvoices: () => ({ routeName: RouteName.Invoices as const }),
  forReturnOrders: () => ({ routeName: RouteName.ReturnOrders as const }),
  forReturnReceipts: () => ({ routeName: RouteName.ReturnReceipts as const }),
  forCreditNotes: () => ({ routeName: RouteName.CreditNotes as const }),
  forShipments: () => ({ routeName: RouteName.Shipments as const }),
  forSalesAgreements: () => ({ routeName: RouteName.SalesAgreements as const }),
  forSalesAgreement: (id: string, agreementLineId?: string) => ({
    routeName: RouteName.SalesAgreement as const,
    params: { id, agreementLine: agreementLineId },
  }),
  forOrderTemplates: () => ({ routeName: RouteName.OrderTemplates as const }),
  forCreateDocFreeReturnOrder: () => ({ routeName: RouteName.CreateDocFreeReturnOrder as const }),
  forCreateDocBasedReturnOrder: (id: string, originalOrderId?: string) => ({
    routeName: RouteName.CreateDocBasedReturnOrder as const,
    params: { id, originalOrderId },
  }),
  forSubAccounts: () => ({ routeName: RouteName.SubAccounts as const }),
  forOrderAuthorizations: () => ({ routeName: RouteName.OrderAuthorizations as const }),
  forCreateProspect: () => ({ routeName: RouteName.CreateProspect as const }),
  forChangePassword: () => ({ routeName: RouteName.ChangePassword as const }),
  forForgotPassword: () => ({ routeName: RouteName.ForgotPassword as const }),
  forCheckout: (asQuote = false, step?: string | null, guest = false) => ({
    routeName: RouteName.Checkout as const,
    params: {
      asQuote,
      guest,
    },
    options: { step },
  }),
  forQuotePromotion: (step?: string | null) => ({
    routeName: RouteName.QuotePromotion as const,
    options: step ? { step } : undefined,
  }),
  forInvoicePayment: (orderId: string) => ({
    routeName: RouteName.InvoicePayment as const,
    params: { orderId },
  }),
  forOrderPayment: (transactionId: string) => ({
    routeName: RouteName.OrderPayment as const,
    params: { transactionId },
  }),
  forPaymentSubmit: (transactionId: string) => ({
    routeName: RouteName.PaymentSubmit as const,
    params: { transactionId },
  }),
  forPaymentCancelled: (transactionId: string) => ({
    routeName: RouteName.PaymentCancelled as const,
    params: { transactionId },
  }),
  forPaymentFailed: (transactionId: string) => ({
    routeName: RouteName.PaymentFailed as const,
    params: { transactionId },
  }),
  forLastViewed: () => ({ routeName: RouteName.LastViewedProducts as const }),
  forNewsOverview: () => ({ routeName: RouteName.NewsOverview as const }),
  forContactUs: () => ({ routeName: RouteName.ContactUs as const }),
  forNewsDetail: (id: string) => ({
    routeName: RouteName.NewsDetail as const,
    params: { id },
  }),
  forWishList: () => ({ routeName: RouteName.WishList as const }),
  forProductComparison: (productIds: string[] | null | undefined) => ({
    routeName: RouteName.ProductComparison as const,
    params: {
      productIds,
    },
  }),
  forOrderSubmit: (transactionId: string) => ({
    routeName: RouteName.OrderSubmit as const,
    params: { transactionId },
  }),
  forPaymentError: (transactionId: string) => ({
    routeName: RouteName.PaymentError as const,
    params: { transactionId },
  }),
  forOrderFailed: (transactionId: string) => ({
    routeName: RouteName.OrderFailed as const,
    params: { transactionId },
  }),
  forOrderCancelled: (transactionId: string) => ({
    routeName: RouteName.OrderCancelled as const,
    params: { transactionId },
  }),
};
