export const parseContent = content => {
  if (!content)
    return content;

  return content.map(
    row => ({
      ...row,
      columns: row.columns.map(column => ({
        ...column,
        contentBlocks: column.contentBlocks.map(parseContentBlock),
      })),
    }),
  );
};

const parseContentBlock = contentBlock => {
  if (!contentBlock)
    return contentBlock;

  if (contentBlock.model)
    contentBlock.model = JSON.parse(contentBlock.model);

  return contentBlock;
};
