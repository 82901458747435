import styles from '../../Checkout.module.scss';
import formStyles from 'components/objects/forms/SanaForm.module.scss';
import { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import LoadingIndicator from '../StepLoadingIndicator';
import { Link } from 'components/primitives/links';
import { routesBuilder } from 'routes';
import { Steps } from 'behavior/pages/checkout';
import { toUrlHash } from 'utils/url';
import { SimpleText, RichText } from 'components/sanaText';
import { GenericForm, GenericFormGroup } from 'components/objects/forms/addons';
import { useOnChange } from 'utils/hooks';
import { generateKey } from 'utils/helpers';
import StepDoneMark from '../StepDoneMark';
import { DangerAlert } from 'components/primitives/alerts';

const CustomerDataStep = ({ className, asLink, isPromotion, isCompleted, children }) => {
  const header = <SimpleText textKey="CheckoutStep_AdditionalPaymentCustomerDataCheckoutStep" />;

  return (
    <section className={className}>
      <div className={styles.header}>
        <h2>
          {asLink
            ? (
              <Link
                to={isPromotion ? routesBuilder.forQuotePromotion(Steps.CustomerData) : routesBuilder.forCheckout(false, Steps.CustomerData)}
                url={toUrlHash(Steps.CustomerData)}
              >
                {header}
              </Link>
            )
            : header
          }
        </h2>
        {isCompleted && <StepDoneMark />}
      </div>
      {children &&
        <div className={styles.body}>
          {children}
        </div>
      }
    </section>
  );
};

CustomerDataStep.propTypes = {
  className: PropTypes.string.isRequired,
  asLink: PropTypes.bool,
  isPromotion: PropTypes.bool,
  isCompleted: PropTypes.bool,
  children: PropTypes.node,
};

export default CustomerDataStep;

// eslint-disable-next-line react/no-multi-comp
export const CustomerDataStepBody = ({ showValidation, ...props }) => (
  <>
    {showValidation &&
      <DangerAlert role="alert" className={styles.validation}>
        <RichText textKey="CustomerDataCheckoutStep_ValidationMessage" />
      </DangerAlert>
    }
    <div className={styles.description}>
      <RichText textKey="PleaseCheckInformationForPaymentProvider" />
    </div>
    <CustomerDataStepForm {...props} />
  </>
);

export const CustomerDataStepForm = memo(({
  customerDataStep,
  formRef,
  onFormSubmit,
  submitFormOnBlur,
  onFormValidate,
}) => {
  const { data, metadata } = customerDataStep;

  useOnChange(() => void (formRef.current = null), [metadata]);

  const formKey = useMemo(() => generateKey(), [metadata]);
  const [billingFields, shippingFields, otherFields]
    = useMemo(() => groupMetadataFields(metadata.fields), [metadata.fields]);

  const onBlur = submitFormOnBlur || onFormValidate
    ? createOnBlurHandler(formRef, onFormSubmit, onFormValidate, submitFormOnBlur)
    : null;

  return (
    <>
      <GenericForm
        key={formKey}
        name="customerData"
        initialValues={data}
        formRef={formRef}
        onSubmit={onFormSubmit}
        onBlur={onBlur}
      >
        {!!otherFields.length &&
          <fieldset className={formStyles.fieldset}>
            {otherFields.map(field => <GenericFormGroup key={field.name} field={field} />)}
          </fieldset>
        }
        {!!billingFields.length &&
          <fieldset className={formStyles.fieldset}>
            <legend className="h4"><RichText textKey="EntityName_BillingAddress" /></legend>
            {billingFields.map(field => <GenericFormGroup key={field.name} field={field} />)}
          </fieldset>
        }
        {!!shippingFields.length &&
          <fieldset className={formStyles.fieldset}>
            <legend className="h4"><RichText textKey="EntityName_ShippingAddress" /></legend>
            {shippingFields.map(field => <GenericFormGroup key={field.name} field={field} />)}
          </fieldset>
        }
      </GenericForm>
      <LoadingIndicator />
    </>
  );
});

CustomerDataStepForm.propTypes = {
  customerDataStep: PropTypes.shape({
    data: PropTypes.object.isRequired,
    metadata: PropTypes.shape({
      fields: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string.isRequired,
      })).isRequired,
    }).isRequired,
  }).isRequired,
  formRef: PropTypes.shape({
    current: PropTypes.object,
  }).isRequired,
  onFormSubmit: PropTypes.func,
  submitFormOnBlur: PropTypes.bool,
  onFormValidate: PropTypes.func,
};

function groupMetadataFields(fields) {
  const shippingFields = fields.filter(field => field.name.startsWith('Shipping_'));
  const billingFields = fields.filter(field => field.name.startsWith('Billing_'));
  const otherFields = fields.filter(field =>
    !shippingFields.includes(field) && !billingFields.includes(field));

  return [billingFields, shippingFields, otherFields];
}

function createOnBlurHandler(formRef, onSubmit, onFormValidate, submitOnBlur) {
  return e => {
    if (e.currentTarget.contains(e.relatedTarget || document.activeElement))
      return;

    formRef.current.validate().then(valid => {
      onFormValidate && onFormValidate(valid);

      if (!valid)
        return;

      submitOnBlur && onSubmit(formRef.current.values);
    });
  };
}
