//TICKET 104230
import linkStyles from 'components/primitives/links/Link.module.scss';
import { SimpleText } from 'components/sanaText';
import { routesBuilder } from 'routes';
import { useRoutes } from 'components/primitives/route';
import React from 'react';

export const  HomeButton = () => {
  //TICKET 104230
  const homeRoute = routesBuilder.forHome();
  const [homePath] = useRoutes([homeRoute]);

  //TICKET 104230

  return (
    <a href={homePath}><SimpleText textKey="Checkout_OCIPunchout_GoHome" className={`${linkStyles.link} ${linkStyles.arrowed}`} /></a>
  );
};
