import { NEWS_HEADLINES_LOADED, NewsHeadlinesAction } from './actions';
import { LANGUAGE_CHANGED, languageChanged } from 'behavior/events';
import type { NewsHeadline } from './types';

type State = {
  items?: NewsHeadline[];
  loaded: boolean;
};

const initialState: State = {
  items: undefined,
  loaded: false,
};

export type Action = NewsHeadlinesAction | ReturnType<typeof languageChanged>

export default function (state = initialState, action: Action): State {
  switch (action.type) {
    case LANGUAGE_CHANGED:
      return { ...state, loaded: false };
    case NEWS_HEADLINES_LOADED:
      return { ...state, ...action.payload, loaded: true };
    default:
      return state;
  }
}
