import { useReducer, useMemo, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { updateCalculatedFields, requestSalesAgreement } from 'behavior/pages/product';
import { useLoadEffect, useQueryString, usePrintMode } from 'utils/hooks';
import ProductContext, { contextInitialValue } from './ProductContext';
import { anyEqual, intersect } from 'utils/helpers';
import { trackViewedProduct } from 'behavior/products/lastViewedTracking';
import { trackProductDetailsView } from 'behavior/analytics';
import { useCanViewUom } from 'components/primitives/product';

function productReducer(state, newData) {
  return { ...state, ...newData };
}

const ProductProvider = ({
  product,
  pricesInclTax,
  children,
  updateCalculatedFields,
  trackViewedProduct,
  lastViewedEnabled,
  trackProductDetailsView,
  requestSalesAgreement,
  agreementId,
  preSelectedLine,
  linesToDisplay,
  allowUomSelection,
}) => {
  const lazyQueryString = useQueryString(true);
  const isPrintMode = usePrintMode();
  const canViewUom = useCanViewUom();
  const updatedUomRef = useRef();
  const trackedProductRef = useRef();
  const trackedProductVariantRef = useRef();

  const [state, updateStateWith] = useReducer(
    productReducer,
    {
      product,
      pricesInclTax,
      lazyQueryString,
    },
    stateInitializer,
  );

  const context = useMemo(() => ({
    ...state,
    updateContext: updateStateWith,
    updateUomId: uomId => {
      updatedUomRef.current = uomId;
      const options = {
        ids: [product.id],
        page: { size: 1, index: 0 },
        uomId,
      };
      updateStateWith({ uomId });
      updateCalculatedFields(options);
    },
  }), [state]);

  useLoadEffect(() => {
    const uomId = canViewUom && context.product.id === product.id
      ? context.uomId
      : product.uom && product.uom.id;

    if (!product.loaded) {
      const options = {
        ids: [product.id],
        page: { size: 1, index: 0 },
        uomId,
      };

      updateCalculatedFields(options);
    }

    const updatedData = { product, uomId };

    if (product.id !== context.product.id)
      updatedData.variantId = null;

    updateStateWith(updatedData);
  }, [product]);

  useEffect(() => {
    let sku;
    if (product.loaded) {
      sku = product;
      if (context.variantId && product.variants && product.variants.length)
        sku = product.variants.find(v => v.id === context.variantId);
    } else {
      if (product.id === context.product.id) {
        sku = context.calculatedInfo;
      }
    }

    updateStateWith({ calculatedInfo: createCalculatedInfo(sku || {}) });
  }, [product, context.variantId]);

  useEffect(() => {
    const hasVariants = product.variants && product.variants.length > 0;
    if (!product.loaded)
      return;

    if (isPrintMode) {
      trackedProductRef.current = null;
      updatedUomRef.current = null;
      return;
    }

    if (canViewUom
      && allowUomSelection
      && preSelectedLine
      && !updatedUomRef.current
      && preSelectedLine?.uom?.id !== product.uom?.id) {
      return;
    }

    if (!hasVariants && trackedProductRef.current !== product) {
      trackProductDetailsView(product);
      trackedProductRef.current = product;
    }
    else if (!!context.variantId
      && (trackedProductVariantRef.current !== context.variantId || trackedProductRef.current !== product)) {
      const sku = product.variants.find(v => v.id === context.variantId);
      trackProductDetailsView({
        ...product,
        price: sku.price,
        variant: context.variantId,
      });
      trackedProductRef.current = product;
      trackedProductVariantRef.current = context.variantId;
    }
  }, [product.loaded, product.uom, context.variantId, linesToDisplay]);

  useEffect(() => {
    if (state.pricesInclTax !== pricesInclTax)
      updateStateWith({ pricesInclTax });
  }, [pricesInclTax]);

  useEffect(() => {
    if (lastViewedEnabled && product)
      trackViewedProduct(product.id.toLowerCase());
  }, [lastViewedEnabled && product && product.id]);

  useEffect(() => {
    if (!product.loaded || !agreementId)
      return;

    requestSalesAgreement(agreementId, product.id);
  }, [agreementId, product.loaded, product.id]);

  return (
    <ProductContext.Provider value={context}>
      {children}
    </ProductContext.Provider>
  );
};

ProductProvider.propTypes = {
  product: PropTypes.shape({
    id: PropTypes.string,
    loaded: PropTypes.bool,
    uom: PropTypes.shape({
      id: PropTypes.string,
    }),
  }),
  pricesInclTax: PropTypes.bool,
  children: PropTypes.node,
  updateCalculatedFields: PropTypes.func.isRequired,
  trackViewedProduct: PropTypes.func.isRequired,
  lastViewedEnabled: PropTypes.bool,
  isMatrixPreset: PropTypes.bool,
  trackProductDetailsView: PropTypes.func.isRequired,
  requestSalesAgreement: PropTypes.func.isRequired,
  agreementId: PropTypes.string,
  preSelectedLine: PropTypes.shape({
    uom: PropTypes.shape({
      id: PropTypes.string,
    }),
  }),
  linesToDisplay: PropTypes.array,
  allowUomSelection: PropTypes.bool,
};

function stateInitializer({ product, pricesInclTax, lazyQueryString }) {
  const { variants, variantComponentGroups, uom } = product;
  const uomId = uom && uom.id;
  const queryUomId = lazyQueryString.values.uomId;
  const queryUomExists = product.uoms && product.uoms.some(uom => uom.id === queryUomId);

  const initialState = {
    ...contextInitialValue,
    product,
    pricesInclTax,
    uomId: queryUomExists ? queryUomId : uomId,
  };

  let sku;
  if (!variants || !variants.length) {
    sku = product;
  } else {
    const variantId = getFirstVariant(variantComponentGroups);
    initialState.variantId = variantId;
    sku = variants.find(v => v.id === variantId);
  }

  if (sku)
    initialState.calculatedInfo = createCalculatedInfo(sku);

  return initialState;
}

function getFirstVariant(variantComponentGroups) {
  if (!variantComponentGroups)
    return null;

  let availableVariants;
  const filter = component => anyEqual(component.variants, availableVariants);

  for (const group of variantComponentGroups) {
    if (!availableVariants)
      availableVariants = group.components[0].variants;
    else {
      const availableComponent = group.components.find(filter);
      availableVariants = intersect(availableComponent.variants, availableVariants);
    }
  }

  return availableVariants[0];
}

function createCalculatedInfo({ price, listPrice, inventory, isOrderable }) {
  return { price, listPrice, inventory, isOrderable };
}

export default connect(
  ({ page: { product, salesAgreement }, user, settings, basket: { salesAgreementInfo } }) => ({
    product,
    pricesInclTax: user.pricesInclTax,
    lastViewedEnabled: settings.lastViewedEnabled,
    agreementId: salesAgreementInfo?.id,
    preSelectedLine: salesAgreement?.preSelectedLine,
    linesToDisplay: salesAgreement?.linesToDisplay,
    allowUomSelection: settings.product.allowUOMSelection,
  }),
  {
    updateCalculatedFields,
    trackViewedProduct,
    trackProductDetailsView,
    requestSalesAgreement,
  },
)(ProductProvider);
