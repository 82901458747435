import { useContext, useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { filter } from 'rxjs/operators';
import { useLoadEffect } from '../hooks';
import AddonContext from './AddonContext';
import { requestAddon } from './actions';
import { normalizeAddonId } from './helpers';
import { StateWithAddons } from './types';

export function useAddon(id: string) {
  const addonId = useMemo(() => normalizeAddonId(id), [id]);
  const { registry } = useContext(AddonContext);
  const dispatch = useDispatch();
  const [, setState] = useState<any>();

  const metadata = useSelector((state: StateWithAddons) => {
    return state.addons.metadata ? state.addons.metadata[addonId] : undefined;
  });
  const hasBundle = metadata && metadata.bundle;
  const hash = hasBundle ? (metadata!.bundle.hash || null) : undefined;
  const bundle = hasBundle ? registry.get(addonId, hash) : (metadata ? null : undefined);

  useLoadEffect(() => {
    if (hasBundle && bundle === undefined)
      dispatch(requestAddon(addonId, hash!));
  }, [addonId, hash, bundle]);

  useEffect(() => {
    if (!hasBundle)
      return;

    const latestBundle = registry.get(addonId, hash);
    if (latestBundle !== bundle)
      setState({});

    const subscription = registry.updates.pipe(
      filter(i => i.id === addonId),
    ).subscribe(e => void (setState(e)));

    return () => subscription.unsubscribe();
  }, [addonId, registry, hasBundle]);

  return {
    exports: bundle,
    metadata,
  };
}
