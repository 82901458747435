import styles from './SalesAgreement.module.scss';
import linkStyles from 'components/primitives/links/Link.module.scss';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useSanaTexts } from 'components/sanaText';
import { makeSimpleText } from 'utils/render';
import AgreementSelector from './AgreementSelector';
import { routesBuilder } from 'routes';
import { Link } from 'components/primitives/links';
import { requestAgreementLines } from 'behavior/basket';

const SalesAgreementTerm = ({
  basketLine: {
    id: basketLineId,
    salesAgreementLines,
    salesAgreementLineId,
    uom: {
      id: uomId,
    },
  },
  salesAgreementId,
  productId,
  requestAgreementLines,
}) => {
  if (!salesAgreementId)
    return null;

  const route = routesBuilder.forSalesAgreement(salesAgreementId, salesAgreementLineId);

  const [
    agreementLine,
    change,
    notUsed,
  ] =
    useSanaTexts(['AgreementLine',
      'ButtonText_Change',
      'SalesAgreementNotUsed',
    ], makeSimpleText).texts;

  const agreementTerm = salesAgreementLineId
    ? <Link to={route} omitScroll className={styles.id}>{salesAgreementLineId}</Link>
    : <span className={styles.id}>{notUsed}</span>;

  const handleChangeTermClick = () => {
    requestAgreementLines(salesAgreementId, productId, basketLineId);
  };

  const agreementSelector = salesAgreementLines?.length > 0
    ? (
      <AgreementSelector
        agreementLines={salesAgreementLines}
        selectedLineId={salesAgreementLineId}
        basketLineId={basketLineId}
        basketLineUomId={uomId}
      />
    )
    : (
      <button type="button" className={linkStyles.link + ' ' + styles.change} onClick={handleChangeTermClick}>
        {change}
      </button>
    );

  return (
    <div className={styles.info}>
      <span className={styles.label}>{agreementLine}:</span>
      <span className={styles.salesAgreementLineValue}>
        {!salesAgreementLines?.length && agreementTerm}
        {agreementSelector}
      </span>
    </div>
  );
};

SalesAgreementTerm.propTypes = {
  basketLine: PropTypes.shape({
    id: PropTypes.string,
    salesAgreementLines: PropTypes.array,
    salesAgreementLineId: PropTypes.string,
    uom: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  }).isRequired,
  productId: PropTypes.string,
  salesAgreementId: PropTypes.string,
  requestAgreementLines: PropTypes.func.isRequired,
};

export default connect(
  ({
    basket: { salesAgreementInfo },
  }) => ({ salesAgreementId: salesAgreementInfo?.id }),
  { requestAgreementLines },
)(SalesAgreementTerm);
