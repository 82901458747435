import styles from '../Checkout.module.scss';
import { useContext } from 'react';
import PropTypes from 'prop-types';
import BasketFooter from '../../basket/footer';
import { useSanaTexts } from 'components/sanaText';
import { makeSimpleText } from 'utils/render';
import CheckoutContext from './CheckoutContext';
import { Steps } from 'behavior/pages/checkout';
import SummaryInfo from '../base/summary/SummaryInfo';

const Summary = ({ info }) => {
  let [subtotalLabel, summaryHeader] = useSanaTexts([
    'SubtotalNProducts',
    'ShoppingCartSummary',
    // Texts to preload ↓.
    'ShippingAddress',
    'CheckoutSummary_ShippingMethods',
    'BillingAddress',
  ]).texts;
  subtotalLabel = makeSimpleText(subtotalLabel, [info.productLines.totalCount]);

  const { activeStep, stepVisited } = useContext(CheckoutContext);

  const showShipping = stepVisited(Steps.Shipping),
    showPayment = info.paymentMethods && stepVisited(Steps.Payment),
    showExtraPayment = !!info.extraPaymentStep?.summaryLine && stepVisited(Steps.ExtraPayment);

  const showProfileAddress = !info.isGuest || (activeStep !== Steps.None && activeStep !== Steps.Address);

  return (
    <>
      <h2 className="h4">{makeSimpleText(summaryHeader)}</h2>
      <BasketFooter
        {...BasketFooter.selectBasketProps(info)}
        readOnly
        className={styles.totals}
        subtotalLabel={subtotalLabel}
        showSalesAgreement
      />
      <SummaryInfo
        info={info}
        showShippingAddress={activeStep !== Steps.None && activeStep !== Steps.Address}
        showBillingAddress={showProfileAddress && !(info.isGuest && !info.billingAddress)}
        showPayment={showPayment}
        showEmailAddress={showProfileAddress}
        showChangeLinks
        showShipping={showShipping}
        showExtraPayment={showExtraPayment}
      />
    </>
  );
};

Summary.propTypes = {
  info: PropTypes.shape({
    productLines: PropTypes.shape({
      totalCount: PropTypes.number.isRequired,
    }).isRequired,
    paymentMethods: PropTypes.any,
    extraPaymentStep: PropTypes.shape({
      summaryLine: PropTypes.string,
    }),
    isGuest: PropTypes.bool,
  }).isRequired,
};

export default Summary;
