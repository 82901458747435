import styles from '../Basket.module.scss';
import { useState, useRef, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import scrollIntoView from 'scroll-into-view';
import { useSanaTexts } from 'components/sanaText';
import {
  modifyBasket,
  saveModifiedBasket,
  Updaters,
} from 'behavior/basket';
import { Presets } from 'behavior/pages/basket';
import { useOmitNavigation } from 'behavior/routing';
import UpdateContext from './UpdateContext';
import PendingChangesContext from './PendingChangesContext';
import { useOnChange } from 'utils/hooks';
import { useExpirationMessage } from '../expirationMessage';
import { makeSimpleText } from 'utils/render';
import { useToasts } from 'react-toast-notifications';
import { useIsPreview } from 'components/objects/preview';
import { showActionForbiddenInPreviewToast } from 'behavior/preview';

const UpdateContextProvider = ({
  children,
  model,
  modifiedDate,
  updatedById,
  modelExpired,
  dispatch,
  isB2BPreset,
}) => {
  const { addToast, removeToast } = useToasts();
  const [pendingChanges, setPendingChangesState] = useState(false);
  const setPendingChanges = useCallback(value => {
    if (typeof requestAnimationFrame === 'function')
      requestAnimationFrame(() => setPendingChangesState(value));
    else
      setPendingChangesState(value);
  }, []);
  const updateContext = useUpdateContextHandler(isB2BPreset, setPendingChanges, dispatch);
  const showToast = useRef();

  const options = ['TheBasketIsRecalculated', 'TheBasketIsCleared'];
  options.disableInsiteEditor = true;

  const { texts: [recalcSuccessMsg, clearSuccessMsg] }
    = useSanaTexts(options, makeSimpleText);
  const modelCleared = model && model.cleared;
  const isEmpty = model && model.productLines && model.productLines.totalCount === 0;

  const postponeNavigationRef = useRef();
  // Save pending changes on leaving basket page.
  const shouldPostponeNavigation = useCallback(_ => {
    const [, modifiedAmount] = updateContext.saveModifiedLines();
    const isModified = modifiedAmount > 0;

    postponeNavigationRef.current = isModified;
    return isModified;
  }, [updateContext]);

  const { resume } = useOmitNavigation(shouldPostponeNavigation);
  useEffect(() => void(postponeNavigationRef.current && resume()), [modifiedDate]);

  useSubmitIfUpdatedExternally(updatedById, modifiedDate, updateContext.submit);

  useEffect(() => {
    if (!model)
      return;

    if (updatedById === Updaters.QuickOrder)
      return;

    const modifiedByBasketDate = updatedById === Updaters.Basket && modifiedDate;
    if (!modifiedByBasketDate || !showToast.current) {
      showToast.current = true;
      return;
    }

    const isCleared = modelCleared || isEmpty;
    const id = 'recalculated' + Date.now();
    let isHidden;

    addToast(
      isCleared ? clearSuccessMsg : recalcSuccessMsg,
      {
        appearance: 'success',
        id,
        options: {
          className: isCleared ? styles.clearedMsg : styles.recalculatedMsg,
          onDismiss: () => {
            isHidden = true;
          },
        },
      });

    return () => {
      if (isHidden || postponeNavigationRef.current)
        return;

      removeToast(id);
    };
  }, [updatedById, modelCleared, isEmpty]);

  const confirmationModal = useExpirationMessage(
    pendingChanges,
    updateContext.reset,
    modelExpired,
    modifiedDate,
    isB2BPreset,
  );

  return (
    <UpdateContext.Provider value={updateContext}>
      <PendingChangesContext.Provider value={pendingChanges}>
        {children}
        {confirmationModal}
      </PendingChangesContext.Provider>
    </UpdateContext.Provider>
  );
};

UpdateContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
  model: PropTypes.shape({
    cleared: PropTypes.bool,
    productLines: PropTypes.shape({
      totalCount: PropTypes.number,
    }),
  }),
  modifiedDate: PropTypes.number,
  updatedById: PropTypes.any,
  modelExpired: PropTypes.bool,
  dispatch: PropTypes.func.isRequired,
  isB2BPreset: PropTypes.bool,
};

export default connect(
  ({
    basket: {
      model,
      modifiedDate,
      updated: { updaterId },
      modelExpired,
    },
    page: { preset },
  }) => ({
    model,
    modifiedDate,
    updatedById: updaterId,
    modelExpired,
    isB2BPreset: preset === Presets.B2B,
  }),
)(UpdateContextProvider);

function useUpdateContextHandler(isB2BPreset, setPendingChanges, dispatch) {
  const isPreview = useIsPreview();

  const [updateContext] = useState(() => {
    const quantities = new Map();
    const linesWithAgreementUpdate = new Map();
    const linesWithAgreementRemoval = new Set();

    return {
      quantities,
      setQuantity(basketLineId, originalQuantity, newQuantity) {
        const { value, isValid } = newQuantity;

        if (originalQuantity === value && isValid) {
          if (quantities.delete(basketLineId) && quantities.size === 0)
            setPendingChanges(false);
        } else {
          quantities.set(basketLineId, newQuantity);

          if (quantities.size && isValid)
            setPendingChanges(true);
        }
      },
      delete(basketLineId) {
        if (isPreview) {
          dispatch(showActionForbiddenInPreviewToast());
        } else {
          quantities.set(basketLineId, { value: 0, isValid: true });
          this.submit(null, null, false);
        }
      },
      writeOnlySubmit() {
        const [valid] = saveModifiedLines();
        return valid;
      },
      submit(e, discountCode, validate) {
        if (e)
          e.preventDefault();

        if (isPreview) {
          dispatch(showActionForbiddenInPreviewToast());
          return false;
        }

        const modified = getModifiedLines(validate);
        if (!modified)
          return false;

        dispatch(modifyBasket(modified, discountCode, !isB2BPreset));
        reset();

        return true;
      },
      getModifiedLines,
      saveModifiedLines,
      reset,
      isB2BPreset,
      linesWithAgreementUpdate,
      linesWithAgreementRemoval,
      setLineAgreement(basketLineId, salesAgreementLineId) {
        linesWithAgreementUpdate.set(basketLineId, salesAgreementLineId);
        linesWithAgreementRemoval.delete(basketLineId);
        setPendingChanges(true);
      },
      deleteLineAgreement(basketLineId) {
        linesWithAgreementRemoval.add(basketLineId);
        linesWithAgreementUpdate.delete(basketLineId);
        setPendingChanges(true);
      },
    };

    function getModifiedLines(validate = true) {
      const modified = [];
      for (const [id, { value, isValid }] of quantities) {
        if (!isValid) {
          if (!validate)
            continue;

          // not valid number
          const input = document.getElementById('qty' + id);
          if (input) {
            scrollIntoView(input, { time: 150 }, () => input.focus());
          }
          return;
        }

        modified.push({ id, quantity: value });
      }

      for (const [basketLineId, newAgreementLineId] of linesWithAgreementUpdate)
        updateOrAddModifiedLine(modified, basketLineId, { newAgreementLineId });

      for (const basketLineId of linesWithAgreementRemoval)
        updateOrAddModifiedLine(modified, basketLineId, { remove: true });

      return modified;
    }

    function saveModifiedLines() {
      if (isPreview)
        return [false, 0];

      const modified = getModifiedLines();
      if (!modified)
        return [false, 0];

      if (modified.length) {
        dispatch(saveModifiedBasket(modified));
        return [true, modified.length];
      }

      return [true, 0];
    }

    function reset() {
      setPendingChanges(false);
      quantities.clear();
    }
  });

  return updateContext;
}

function updateOrAddModifiedLine(modifiedLines, basketLineId, salesAgreementLineAction) {
  const index = modifiedLines.findIndex(({ id }) => id === basketLineId);
  if (index > 0)
    modifiedLines[index].salesAgreementLineAction = salesAgreementLineAction;
  else
    modifiedLines.push({ id: basketLineId, salesAgreementLineAction });
}

function useSubmitIfUpdatedExternally(updatedById, modifiedDate, callback) {
  const prevDateRef = useRef(modifiedDate);

  useOnChange(() => {
    if (!updatedById
      || updatedById === Updaters.Basket
      || updatedById === Updaters.Sync
      || updatedById === Updaters.QuickOrder)
      return;

    if (prevDateRef >= modifiedDate)
      return;

    callback(null, null, false);
  }, [updatedById], false);
}
