import styles from '../Checkout.module.scss';
import { memo, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'components/primitives/grid';
import { CheckoutProvider } from './CheckoutProvider';
import CheckoutSteps from './CheckoutSteps';
import { useIsMobileViewport } from 'utils/hooks';
import Summary from './Summary';

//TICKET 104230
import { useHasAbilities } from 'components/objects/user';
import { AbilityTo } from 'behavior/user/constants';
import { RichText } from '../../../sanaText';
import { HomeButton } from 'components/objects/checkout/base/HomeButton';
import CheckoutLogoutButton from 'components/objects/checkout/base/CheckoutLogoutButton';
import { logout } from 'behavior/user';
import { connect } from 'react-redux';
//TICKET 104230

const MultiStepCheckout = ({ info, logout }) => {
  const isMobile = useIsMobileViewport();
  //TICKET 104230
  const OCIPunschOutAbility = useHasAbilities(AbilityTo.OCIPunschOutAbility)[0];
  const ociForm = useRef();
  useEffect(() => {
    if (OCIPunschOutAbility) {
      logout();
      ociForm.current.submit();
    }
  }, []);
  //TICKET 104230

  return (
    <>
      {OCIPunschOutAbility &&
        <div className="basket-new">
          <div><RichText textKey="Checkout_OCIPunchout_Description" /></div>
          <div className="Link_arrowed"> <HomeButton /></div>
          <div>   <CheckoutLogoutButton />   </div>
          <form method="POST" name="BASKET" id="BASKET" action={info.oCIHookUrl} style={{ display: 'none' }} ref={ociForm}>
            {info.oCIBaksket.map(function (node, index) {
              return <input type="hidden" id={node.name} name={node.name} value={node.value ? node.value : ''} key={index} />;
            })}
          </form>
          {/*<iframe name="test" id="test" title="Test" style={{ display: 'none' }} />*/}
        </div>
      }
      {!OCIPunschOutAbility &&
        <CheckoutProvider info={info}>
          <Row>
            <Col xs="12" md="7" lg="8" xl="9" className={`${styles.steps} ${styles.multiStep}`}>
              <CheckoutSteps info={info} />
            </Col>
            {!isMobile &&
              <Col md="5" lg="4" xl="3" className={`${styles.rightPanel} ${styles.noMethods}`}>
                <Summary info={info} />
              </Col>
            }
          </Row>
        </CheckoutProvider>
      }
    </>
  );
};

MultiStepCheckout.propTypes = {
  info: PropTypes.object.isRequired,
};

export default memo(connect(undefined, { logout })(MultiStepCheckout));
