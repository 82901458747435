import styles from '../checkout/Checkout.module.scss';
import { useCallback } from 'react';
import PropTypes from 'prop-types';
import Field, { fieldPropTypes } from 'components/objects/forms/fields/Field';
import PaymentMethodsList from 'components/objects/checkout/base/payment/PaymentMethodsList';
import { makeRichText } from 'utils/render';
import { DangerAlert } from 'components/primitives/alerts';

const componentBuilder = ({ field, form, onChange, ...props }) => {
  const { value, name } = field;
  const { setFieldValue, touched, setFieldTouched } = form;

  const onSelect = useCallback(selectedMethodId => {
    setFieldValue(name, selectedMethodId);
    setFieldTouched(name, true, false);
    onChange(selectedMethodId);
  }, [name, setFieldValue, onChange, setFieldTouched]);

  return (
    <>
      {touched[name] && form.errors[name] &&
        <DangerAlert role="alert" className={styles.validation}>
          {makeRichText(form.errors[name])}
        </DangerAlert>
      }
      <PaymentMethodsList selectedMethodId={value} onSelect={onSelect} {...props} />
    </>
  );
};

// eslint-disable-next-line react/no-multi-comp
const PaymentMethodsListField = props => (
  <Field {...props} component={componentBuilder} />
);

PaymentMethodsListField.propTypes = {
  ...fieldPropTypes,
  onChange: PropTypes.func.isRequired,
  paymentMethods: PropTypes.array.isRequired,
  currency: PropTypes.object.isRequired,
};

export default PaymentMethodsListField;
