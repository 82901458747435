import { map } from 'rxjs/operators';
import { of } from 'rxjs';
import { PageComponentNames } from '../componentNames';
import { createNotFoundPageResult, initComponent } from '../helpers';
import { agreementDetailPageQuery } from './queries';
import { requestAgreementLinesAvailability } from './actions';

export default function salesAgreementHandler({ params: { id: agreementId, agreementLine } }, state$, { api }) {
  return api.graphApi(agreementDetailPageQuery, { agreementId }).pipe(
    map(data => mapData(data, state$, agreementLine)),
    initComponent(PageComponentNames.SalesAgreement),
    map(data => {
      if (!data || !data.page)
        return createNotFoundPageResult(data);

      if (!data.page.agreement)
        return data;

      return {
        ...data,
        action$: of(requestAgreementLinesAvailability(data.page.agreement)),
      };
    }),
  );
}

function mapData(
  {
    pages: {
      salesAgreement: page,
    },
    basket,
  },
  state$,
  agreementLineId,
) {

  if (!page || !page.agreement)
    return null;

  for (const line of page.agreement.lines) {
    if (line.category) {
      if (line.category.type === 'NotExistingProductCategory') {
        delete line.category.type;
        line.category.exists = false;
      } else {
        line.category.exists = true;
      }
    }
  }

  page.agreement.expandedAgreementLineId = agreementLineId;

  const currentPageState = state$.value.page;
  if (currentPageState && currentPageState.agreement && currentPageState.agreement.id === page.agreement.id)
    page.search = currentPageState.search;

  return { ...page, basket };
}
