exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".SalesAgreement_info{color:var(--table_Header_FontColor,grey);font-size:var(--normalText_SmallerFontSize,12px)}.SalesAgreement_id,.SalesAgreement_label{margin-right:5px}.SalesAgreement_change{\n  /*!*/}", ""]);

// exports
exports.locals = {
	"info": "SalesAgreement_info",
	"id": "SalesAgreement_id",
	"label": "SalesAgreement_label",
	"change": "SalesAgreement_change"
};