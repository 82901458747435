import { map } from 'rxjs/operators';
import { PageComponentNames } from '../componentNames';
import { invoicePaymentPageQuery } from './queries';
import { loadSystemPageQuery, initSystemPageContent } from 'behavior/pages/system';

export default (routeData, _, { api }) => {
  const { params: { orderId, previewToken } } = routeData;

  if (previewToken) {
    return api.graphApi(loadSystemPageQuery('invoicePayment')).pipe(
      map(({ pages: { invoicePayment } }) => ({
        page: {
          ...invoicePayment,
          component: PageComponentNames.InvoicePayment,
          order: {
            id: '',
            currency: { id: '', symbol: '' },
            orderDate: '-',
            referenceNo: '-',
          },
          paymentMethods: Array.from(Array(3)).map((_, index) => ({
            id: index.toString(),
            name: '',
            isDefault: false,
          })),
        },
      })),
      initSystemPageContent(),
    );
  }

  return api.graphApi(invoicePaymentPageQuery, { orderId }).pipe(
    map(({ 
      pages: { invoicePayment: invoicePaymentPage },
      invoicePayment: invoicePaymentInfo,
    }) => {
      if (!invoicePaymentInfo)
        return null;

      const { order, paymentMethods: { list: paymentMethods } } = invoicePaymentInfo;

      return {
        page: {
          component: PageComponentNames.InvoicePayment,
          order,
          paymentMethods: paymentMethods.map(({ info }) => info),
          ...invoicePaymentPage,
        },
      };
    }),
    initSystemPageContent(),
  );
};