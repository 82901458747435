import type { ClientRoute } from './types';

type Params = {
  id?: string;
  agreementLine?: string;
}

const productsWithCategoryRoute = (): ClientRoute => {
  const path = '/products-with-category/';

  return {
    getVirtualPath(params) {
      if (!params)
        paramsAreNotFound();

      const { id, agreementLine } = params as Params;

      if (!id)
        paramsAreNotFound();

      if (!agreementLine)
        return path + id.toLowerCase();

      return `${path}${id.toLowerCase()}?agreement-line=${agreementLine}`;
    },
  };
};

function paramsAreNotFound(): never {
  throw new Error('Can\'t build URL for the page. Category ID was not specified.');
}

export default productsWithCategoryRoute;
