import styles from './PLP.module.scss';
import PropTypes from 'prop-types';
import { connectToContext } from 'utils/react';
import ProductContext from './ProductContext';
import { connect } from 'react-redux';
import { Link } from 'components/primitives/links';
import { WithProductClickTracking } from 'components/objects/analytics';
import Spinner from 'components/primitives/spinner/Spinner';
import WithProductRoute from './WithProductRoute';
import { ProductThumbnail } from 'components/primitives/product';
import { useIsPreview } from 'components/objects/preview';

const Thumbnail = ({ id, url, title, image, noImage }) => {
  const isPreview = useIsPreview();
  
  if (!image && !noImage)
    return null;

  if (isPreview && !url) {
    return (
      <div className={styles.thumbnail} aria-hidden>
        <ProductThumbnail src={image} noImageSrc={noImage} title={title} />
      </div>
    );
  }

  return (
    <div className={styles.thumbnail} aria-hidden>
      <WithProductClickTracking>
        {handleClick => (
          <WithProductRoute product={{ id, url }}>
            {(productUrl, productRouteBuilder) => (
              <Link onClick={handleClick} url={productUrl} to={productRouteBuilder} tabIndex="-1">
                <ProductThumbnail
                  src={image}
                  noImageSrc={noImage}
                  title={title}
                  className={styles.productImg}
                  wrapperClassName={styles.productImgWrapper}
                  placeholder={<Spinner />}
                />
              </Link>
            )}
          </WithProductRoute>
        )}
      </WithProductClickTracking>
    </div>
  );
};

Thumbnail.propTypes = {
  id: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  image: PropTypes.string,
  noImage: PropTypes.string,
};

const ConnectedComponent = connect(mapStateToProps)(Thumbnail);

export default connectToContext(
  [ProductContext],
  ({ id, url, title, image }, { gridMode }) => ({
    id,
    url,
    title,
    image: image && (
      gridMode
        ? image.medium || image.small
        : image.small
    ),
  }),
)(ConnectedComponent);

function mapStateToProps(state, ownProps) {
  const { gridMode } = ownProps;
  const {
    settings: { product: productSettings },
  } = state;

  if (!productSettings)
    return { noImage: null };

  return {
    noImage: gridMode
      ? productSettings.noImage.medium
      : productSettings.noImage.small,
  };
}