import {
  PRODUCT_CALCULATED_FIELDS_LOADED,
  REVIEWS_RECEIVED,
  REVIEW_PROCESSED,
  SALES_AGREEMENT_PRODUCT_VARIANT_CHANGED,
  SALES_AGREEMENT_RECEIVED,
  VOLUME_PRICES_RECEIVED,
} from './actions';
import { VIEWER_CHANGED } from 'behavior/events';
import { createReducer } from 'utils/redux';
import { deleteProductCalculatedInfo } from 'behavior/products/product';
import { getAgreementLineById } from 'behavior/salesAgreements';

export default createReducer(null, {
  [PRODUCT_CALCULATED_FIELDS_LOADED]: onProductLoaded,
  [REVIEWS_RECEIVED]: onReviewsReceived,
  [REVIEW_PROCESSED]: onReviewProcessed,
  [VOLUME_PRICES_RECEIVED]: onVolumePricesReceived,
  [VIEWER_CHANGED]: onViewerChanged,
  [SALES_AGREEMENT_RECEIVED]: onSalesAgreementReceived,
  [SALES_AGREEMENT_PRODUCT_VARIANT_CHANGED]: onSalesAgreementVariantChanged,
});

function onProductLoaded(state, action) {
  const stateVariants = state.product && state.product.variants;
  const { variants } = action.payload;

  if (variants) {
    for (const variant of variants) {
      const stateVariant = stateVariants.find(v => v.id === variant.id);
      if (stateVariant)
        variant.bomComponents = stateVariant.bomComponents;
    }
  }

  return {
    ...state,
    product: {
      ...state.product,
      ...action.payload,
      loaded: true,
    },
  };
}

function onReviewsReceived(state, action) {
  return {
    ...state,
    product: {
      ...state.product,
      reviews: {
        total: state.product.reviews.total,
        avg: state.product.reviews.avg,
        list: state.product.reviews.list.concat(action.payload),
      },
    },
  };
}

function onReviewProcessed(state, action) {
  return {
    ...state,
    product: {
      ...state.product,
      reviews: {
        ...state.product.reviews,
        saved: action.payload ? Date.now() : null,
      },
    },
  };
}

function onVolumePricesReceived(state, action) {
  return {
    ...state,
    volumePrices: action.payload,
  };
}

function onViewerChanged(state, _action) {
  if (!state.product)
    return state;

  const product = deleteProductCalculatedInfo(state.product);
  return { ...state, product };
}

function onSalesAgreementReceived(state, { payload: { agreement, linesAvailability } }) {
  if (!agreement)
    return state;

  const variantId = state.salesAgreement?.variantId;
  const linesToDisplay = buildAgreementLinesToDisplay(agreement.lines, linesAvailability, variantId);

  return {
    ...state,
    salesAgreement: {
      ...agreement,
      variantId,
      linesToDisplay,
      linesAvailability,
      preSelectedLine: getAgreementLineById(linesToDisplay, state.salesAgreement?.preSelectedLine?.id),
    },
  };
}

function onSalesAgreementVariantChanged(state, { payload: { variantId } }) {
  const agreement = state.salesAgreement || {
    lines: [],
    linesAvailability: [],
  };

  const linesToDisplay = buildAgreementLinesToDisplay(agreement.lines, agreement.linesAvailability, variantId);

  return {
    ...state,
    salesAgreement: {
      ...agreement,
      variantId,
      linesToDisplay,
      preSelectedLine: getAgreementLineById(linesToDisplay, state.salesAgreement?.preSelectedLine?.id),
    },
  };
}

function buildAgreementLinesToDisplay(lines, linesAvailability, variantId = null) {
  if (!linesAvailability)
    return [];

  return lines.filter(line => linesAvailability.some(availability =>
    availability.lineId === line.id
    && areEqualOrEmpty(variantId, availability.variantId)
    && (!line.isMaxEnforced || line.quantities.remaining > 0 || line.amounts.remaining > 0),
  ));
}

function areEqualOrEmpty(value1, value2) {
  return ((!value1 && !value2) || value1 === value2);
}