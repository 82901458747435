import { createReducer } from 'utils/redux';
import { AGREEMENTS_RECEIVED, AGREEMENTS_SEARCH_PRODUCT_IDS_RECEIVED, AGREEMENT_LINES_AVAILABILITY_RECEIVED } from './actions';

export default createReducer(null, {
  [AGREEMENTS_RECEIVED]: onAgreementsReceived,
  [AGREEMENTS_SEARCH_PRODUCT_IDS_RECEIVED]: onSearchProductIdsReceived,
  [AGREEMENT_LINES_AVAILABILITY_RECEIVED]: onAgreementLinesAvailabilityReceived,
});

function onAgreementsReceived(state, { payload }) {
  const newAgreements = payload.agreements || [];
  const agreements = payload.append && state.agreements
    ? state.agreements.concat(newAgreements)
    : newAgreements;

  return { ...state, agreements };
}

function onSearchProductIdsReceived(state, { payload: search }) {
  return { ...state, search };
}

function onAgreementLinesAvailabilityReceived(state, { payload: availableLineIds }) {
  if (availableLineIds.length === 0)
    return state;

  const lines = state.agreement.lines.map(line => ({
    ...line,
    isLineOrderable: availableLineIds.some(({ lineId }) => lineId === line.id),
  }));
  return {
    ...state,
    agreement: {
      ...(state.agreement),
      lines,
    },
  };
}