import styles from '../Checkout.module.scss';
import { memo, useRef, useContext, useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { selectPaymentMethod } from 'behavior/pages/checkout';
import StepButtons from './StepButtons';
import {
  PaymentMethodStep as PaymentMethodStepBase,
  PaymentMethodStepBody,
} from '../base/payment';
import CheckoutContext from './CheckoutContext';
import scrollIntoView from 'scroll-into-view';
import { useOnChange } from 'utils/hooks';
import { useSanaTexts } from 'components/sanaText';
import { makeSimpleText } from 'utils/render';

const PaymentMethodStep = ({ info, className, asLink, isActive, isCompleted }) => {
  const { nextStep, redirected } = useContext(CheckoutContext);
  const [showValidation, setShowValidation] = useState();

  const dispatch = useDispatch();

  const selectedMethodIdRef = useRef();

  useOnChange(() => {
    selectedMethodIdRef.current = info.paymentMethodId || (info.paymentMethods?.length === 1 ? info.paymentMethods[0].id : null);
    setShowValidation(redirected);
  }, [info.paymentMethodId, info.paymentMethods, isActive]);

  const handleSelect = useCallback(id => void (selectedMethodIdRef.current = id), []);
  const disabled = !info.paymentMethods.length;

  const handleSubmit = useMemo(() => disabled ? null : (e => {
    e.preventDefault();

    if (selectedMethodIdRef.current) {
      nextStep();
      dispatch(selectPaymentMethod(selectedMethodIdRef.current));
    } else {
      setShowValidation(true);
      setTimeout(() => scrollIntoView(document.querySelector(`.${styles.validation}`), { time: 200 }), 10);
    }
  }), [disabled, nextStep]);

  const [validationMsg] = useSanaTexts(['PaymentMethods_ValidationMessage'], makeSimpleText).texts;

  return (
    <PaymentMethodStepBase
      asLink={asLink}
      className={className}
      isQuote={info.isQuote}
      isPromotion={!!info.quote}
      isCompleted={isCompleted}
    >
      {isActive &&
        <>
          <PaymentMethodStepBody
            totals={info.totals}
            paymentMethods={info.paymentMethods}
            selectedMethodId={selectedMethodIdRef.current}
            recheck={info.recheckPaymentMethod}
            onSelect={handleSelect}
            onSubmit={handleSubmit}
            isQuote={info.isQuote}
            validationMsg={showValidation && validationMsg}
          />
          <StepButtons onStepSubmit={handleSubmit} disableNext={disabled} />
        </>
      }
    </PaymentMethodStepBase>
  );
};

PaymentMethodStep.propTypes = {
  info: PropTypes.shape({
    isQuote: PropTypes.bool,
    paymentMethods: PropTypes.array,
    totals: PropTypes.object,
    paymentMethodId: PropTypes.string,
    quote: PropTypes.object,
  }).isRequired,
  className: PropTypes.string,
  isActive: PropTypes.bool,
  asLink: PropTypes.bool,
  isCompleted: PropTypes.bool,
};

export default memo(PaymentMethodStep);
