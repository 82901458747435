export function getAgreementLineById(lines, id) {
  if (!lines || !id)
    return null;

  return lines.find(l => l.id === id) ?? null;
}

export function appendAgreementLineToUrl(url, salesAgreementLineId) {
  if (!url || !salesAgreementLineId)
    return url;

  const querySeparator = url.includes('?') ? '&' : '?';
  return `${url}${querySeparator}agreement-line=${salesAgreementLineId}`;
}