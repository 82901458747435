import type { ReactNode } from 'react';
import { useDispatch } from 'react-redux';
import { goBack } from 'behavior/routing';

type Props = {
  children?: ReactNode;
}

const BackLink = ({ children, ...attributes }: Props) => {
  const dispatch = useDispatch();

  const onClick = (event: React.MouseEvent) => {
    if (event.button === 1)
      return;

    event.preventDefault();
    dispatch(goBack());
  };

  return (
    <a href="/" onClick={onClick} {...attributes}>
      {children}
    </a>
  );
};

export default BackLink;
