import styles from './LanguageToggle.module.scss';
import { memo } from 'react';
import PropTypes from 'prop-types';
import useLanguageToggleBehavior from './useLanguageToggleBehavior';
import { Dropdown } from 'components/primitives/dropdown';
import Header from './Header';
import Body from './Body';
import { Placeholder } from 'components/primitives/placeholders';

const LanguageToggleBase = ({ options: { togglePresentation, showFlagsInDropdown }, isDesignerMode }) => {
  const { 
    selectedLanguage,
    sortedLanguages,
    alternateUrls,
    loadAlternateUrls,
  } = useLanguageToggleBehavior();

  if (sortedLanguages.length <= 1 && !isDesignerMode)
    return null;

  return (
    <Dropdown 
      className="language" 
      onDropDownShow={loadAlternateUrls} 
      header={<Header selectedLanguage={selectedLanguage} presentation={togglePresentation} />}
    >
      {!isDesignerMode && (
        alternateUrls && alternateUrls.length
          ? (
            <Body 
              selectedLanguage={selectedLanguage} 
              languages={sortedLanguages} 
              alternateUrls={alternateUrls}
              showFlags={showFlagsInDropdown}
            />
          )
          : (
            <div className={styles.placeholder}>
              <Placeholder lineCount={sortedLanguages.length} className={styles.placeholderItem} />
            </div>
          )
      )}
    </Dropdown>
  );
};

LanguageToggleBase.propTypes = {
  options: PropTypes.shape({
    togglePresentation: PropTypes.number.isRequired,
    showFlagsInDropdown: PropTypes.bool.isRequired,
  }).isRequired,
  isDesignerMode: PropTypes.bool,
};

export default memo(LanguageToggleBase);
